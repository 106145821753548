import React, { useState, Fragment, useEffect } from 'react';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';
import { FloatingLabel, Form, Button } from 'react-bootstrap'
import { Bar } from "react-chartjs-2";
import DataTable from '@ashvin27/react-datatable';
import Datepicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { DateTimeForm, filterallbookinghook, filterallexperienceshook, filterallroomshook, filterguestuserhook, filterhostuserhook, getallbookingshook, getallguestuserhook, getallhostuserhook, getallroomshook, gethostingexperiencehook } from '../../../Hooks/adminHook';
import axios from 'axios'
const FormView = () => {
    const [category, setCategory] = useState("guestusers");
    const [from, setFrom] = useState('');
    const [to, setTo] = useState('');
    const [user, setUser] = useState([]);
    const [guestuser, setGuestuser] = useState([]);
    const [hostuser, setHostuser] = useState([]);
    const [booking, setBooking] = useState([]);
    const [rooms, setRooms] = useState([]);
    const [experience, setExperience] = useState([]);
    const [experiencereservation, setExperiencereservation] = useState([]);
    const [totalrecordcounts, setTotalRecordCounts] = useState(0)
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    //    const config = {};
    useEffect(() => {
        async function fetchdata() {
            let reqData = {
                page: page,
                limit: limit
            }
            //  config = config2
            await getallguestuserhook(reqData).then((result) => {
                console.log("get all guestuser result", result);
                // setCategory("guestusers");
                result?.data?.data?.record?.map((data, i) => data.serial = i + 1)
                setGuestuser(result?.data?.data?.record);
                setTotalRecordCounts(result?.data?.data?.count);
                //  config = config1
            })
            if (category == "guestusers" && from?.length > 0 && to?.length > 0) {
                if (from <= to) {
                    let payload = {
                        startdate: from,
                        enddate: to,
                        type: "both"
                    };
                    console.log("paayload", payload);
                    await filterguestuserhook(payload).then((result) => {
                        console.log("filter guest user hook result", result?.data?.record);
                        result?.data?.record?.map((data, i) => data.serial = i + 1)
                        setGuestuser(result?.data?.record);
                        setTotalRecordCounts(result?.data?.count)
                    })
                }
                else {
                    window.alert("You must select to date greater than or equal to from date !..");
                    setTo('');
                }
            }
            // if(category == "guestusers" && !from?.length && !to?.length)
            // {
            //     await getallguestuserhook().then((result)=>{
            //         console.log("get all guestuser result" , result);
            //     })
            // }
            if (category == "guestusers" && from?.length > 0 && !to?.length) {
                let payload = {
                    startdate: from,
                    type: "from"
                };
                console.log("paayload", payload);
                await filterguestuserhook(payload).then((result) => {
                    console.log("filter guest user hook result", result);
                    result?.data?.record?.map((data, i) => data.serial = i + 1)
                    setGuestuser(result?.data?.record);
                    setTotalRecordCounts(result?.data?.count);
                })
            }

            if (category == "guestusers" && !from?.length && to?.length > 0) {
                let payload = {
                    enddate: to,
                    type: "to"
                };
                console.log("paayload", payload);
                await filterguestuserhook(payload).then((result) => {
                    console.log("filter guest user hook result", result);
                    result?.data?.record?.map((data, i) => data.serial = i + 1)
                    setGuestuser(result?.data?.record);
                    setTotalRecordCounts(result?.data?.count);
                })
            }
            if (category == "hostusers" && from?.length > 0 && to?.length > 0) {
                if (from <= to) {
                    let payload = {
                        type: "both",
                        startdate: from,
                        enddate: to
                    }
                    console.log("payload", payload);
                    await filterhostuserhook(payload).then((result) => {
                        console.log("filter host user hook result", result);
                        result?.data?.record?.map((data, i) => data.serial = i + 1)
                        setHostuser(result?.data?.record);
                        setTotalRecordCounts(result?.data?.count)
                    })
                }
                else {
                    window.alert("You must select to date greaer than or equal to from date !..");
                    setTo('');
                }
            }
            if (category == "hostusers" && !from?.length && !to?.length) {

                await getallhostuserhook(reqData).then((result) => {
                    console.log("get all host user hook result", result);
                    result?.data?.data?.record?.map((data, i) => data.serial = i + 1)
                    setHostuser(result?.data?.data?.record);
                    setTotalRecordCounts(result?.data?.data?.count)
                })
            }
            if (category == "hostusers" && from?.length > 0 && !to?.length) {
                let payload = {
                    type: "from",
                    startdate: from,
                }
                console.log("payload", payload);
                await filterhostuserhook(payload).then((result) => {
                    console.log("filter host user hook result", result);
                    result?.data?.record?.map((data, i) => data.serial = i + 1)
                    setHostuser(result?.data?.record);
                    setTotalRecordCounts(result?.data?.count);
                })
            }
            if (category == "hostusers" && !from?.length && to?.length > 0) {
                let payload = {
                    type: "to",
                    enddate: to
                }
                console.log("payload", payload);
                await filterhostuserhook(payload).then((result) => {
                    console.log("filter host user hook result", result);
                    result?.data?.record?.map((data, i) => data.serial = i + 1)
                    setHostuser(result?.data?.record);
                    setTotalRecordCounts(result?.data?.count);
                })
            }

            if (category == "bookings" && from?.length > 0 && to?.length > 0) {
                if (from <= to) {
                    let payload = {
                        startdate: from,
                        enddate: to,
                        type: "both"
                    }
                    console.log("payload", payload);
                    await filterallbookinghook(payload).then((result) => {
                        console.log("filter all booking hook result", result);
                        result?.data?.record?.map((data, i) => data.serial = i + 1)
                        setBooking(result?.data?.record);
                        setTotalRecordCounts(result?.data?.count)
                    })
                }
                else {
                    window.alert("You must select to date greaer than or equal to from date !..");
                    setTo('');
                }
            }
            if (category == "bookings" && !from?.length && !to?.length) {
                await getallbookingshook(reqData).then((result) => {
                    console.log("get all booking hook result", result);
                    result?.data?.data?.record?.map((data, i) => data.serial = i + 1)
                    setBooking(result?.data?.data?.record);
                    setTotalRecordCounts(result?.data?.data?.count)
                })
            }
            if (category == "bookings" && from?.length > 0 && !to?.length) {
                let payload = {
                    startdate: from,

                    type: "from"
                }
                console.log("payload", payload);
                await filterallbookinghook(payload).then((result) => {
                    console.log("filter all booking hook result", result);
                    result?.data?.record?.map((data, i) => data.serial = i + 1)
                    setBooking(result?.data?.record);
                    setTotalRecordCounts(result?.data?.count);
                })
            }
            if (category == "bookings" && !from?.length && to?.length > 0) {
                let payload = {
                    enddate: to,
                    type: "to"
                }
                console.log("payload", payload);
                await filterallbookinghook(payload).then((result) => {
                    console.log("filter all booking hook result", result);
                    result?.data?.record?.map((data, i) => data.serial = i + 1)
                    setBooking(result?.data?.record);
                    setTotalRecordCounts(result?.data?.count);
                })
            }

            if (category == "rooms" && from?.length > 0 && to?.length > 0) {
                if (from <= to) {
                    let payload = {
                        startdate: from,
                        enddate: to,
                        type: "both"
                    }
                    console.log("payload", payload);
                    await filterallroomshook(payload).then((result) => {
                        console.log("filter all room hook result", result);
                        result?.data?.record?.map((data, i) => data.serial = i + 1)
                        setRooms(result?.data?.record);
                        setTotalRecordCounts(result?.data?.count);
                    })
                }
                else {
                    window.alert("You must select to date greater than or equal to from date !..");
                    setTo('');
                }
            }
            if (category == "rooms" && !from?.length && !to?.length) {
                await getallroomshook(reqData).then((result) => {
                    console.log("get all rooms hook result", result);
                    result?.data?.data?.record?.map((data, i) => data.serial = i + 1)
                    setRooms(result?.data?.data?.record);
                    setTotalRecordCounts(result?.data?.data?.count)
                })
            }
            if (category == "rooms" && from?.length > 0 && !to?.length) {
                let payload = {
                    startdate: from,
                    type: "from"
                }
                console.log("payload", payload);
                await filterallroomshook(payload).then((result) => {
                    console.log("filter all room hook result", result);
                    result?.data?.record?.map((data, i) => data.serial = i + 1)
                    setRooms(result?.data?.record);
                    setTotalRecordCounts(result?.data?.count);
                })
            }
            if (category == "rooms" && !from?.length && to?.length > 0) {
                let payload = {
                    enddate: to,
                    type: "to"
                }
                console.log("payload", payload);
                await filterallroomshook(payload).then((result) => {
                    console.log("filter all room hook result", result);
                    result?.data?.record?.map((data, i) => data.serial = i + 1)
                    setRooms(result?.data?.record);
                    setTotalRecordCounts(result?.data?.count);
                })
            }

            if (category == "experiences" && from?.length > 0 && to?.length > 0) {
                if (from <= to) {
                    let payload = {
                        type: "both",
                        startdate: from,
                        enddate: to
                    }
                    console.log("payload", payload);
                    await filterallexperienceshook(payload).then((result) => {
                        console.log("filter all experiences hook result", result);
                        setExperience(result?.data?.record);
                        setTotalRecordCounts(result?.data?.count);
                    })
                }
                else {
                    window.alert("You must select to date greaer than or equal to from date !..")
                    setTo('');
                }
            }
            if (category == "experiences" && !from?.length && !to?.length) {
                await gethostingexperiencehook(reqData).then((result) => {
                    console.log("get hosting experience hook result", result);
                    result?.data?.data?.record?.map((data, i) => data.serial = i + 1)
                    setExperience(result?.data?.data?.record);
                    setTotalRecordCounts(result?.data?.data?.count);
                })
            }
            if (category == "experiences" && from?.length > 0 && !to?.length) {
                let payload = {
                    type: "from",
                    startdate: from
                }
                console.log("payload", payload);
                await filterallexperienceshook(payload).then((result) => {
                    console.log("filter all experiences hook result", result);
                    setExperience(result?.data?.record);
                    setTotalRecordCounts(result?.data?.count);
                })
            }
            if (category == "experiences" && !from?.length && to?.length > 0) {
                let payload = {
                    type: "to",
                    enddate: to
                }
                console.log("payload", payload);
                await filterallexperienceshook(payload).then((result) => {
                    console.log("filter all experiences hook result", result);
                    setExperience(result?.data?.record);
                    setTotalRecordCounts(result?.data?.count);
                })
            }
        }
        fetchdata();

    }, [from, to, category])
    // const columns = [
    //     {
    //         key: "id",
    //         text: "Id",
    //         className: "name",
    //         align: "left",
    //         sortable: true,
    //     },
    //     {
    //         key: "reserveid",
    //         text: "Reserve Id",
    //         className: "name",
    //         align: "left",
    //         sortable: true,
    //     },
    //     {
    //         key: "Roomname",
    //         text: "Room Name",
    //         className: "name",
    //         align: "left",
    //         sortable: true,
    //     },
    //     {
    //         key: "username",
    //         text: "User Name",
    //         className: "name",
    //         align: "left",
    //         sortable: true,
    //     },
    //     {
    //         key: "emailid",
    //         text: "Email",
    //         className: "name",
    //         align: "left",
    //         sortable: true,
    //     },
    //     {
    //         key: "userdatafrom",
    //         text: "Id",
    //         className: "name",
    //         align: "left",
    //         sortable: true,
    //     },
    //     {
    //         key: "userdatato",
    //         text: "Id",
    //         className: "name",
    //         align: "left",
    //         sortable: true,
    //     },
    //     {
    //         key: "iddocuments",
    //         text: "Id",
    //         className: "name",
    //         align: "left",
    //         sortable: true,
    //     },
    //     {
    //         key: "staus",
    //         text: "Id",
    //         className: "name",
    //         align: "left",
    //         sortable: true,
    //     },
    //     {
    //         key: "action",
    //         text: "Edit action",
    //         className: "action",
    //         width: 100,
    //         align: "left",
    //         sortable: true,
    //         cell: record => {
    //             return (
    //                 <Fragment>
    //                     {/* <button
    //                         className="btn btn-primary btn-sm"
    //                         // onClick={() => viewRecord(record)}
    //                         style={{ marginRight: '5px' }}>
    //                         <i class="fa fa-eye mr-0" ></i>
    //                     </button> */}
    //                     <button
    //                         className="btn btn-primary btn-sm"
    //                         // onClick={() => editRecord(record)}
    //                         // style={{ marginRight: '5px' }}
    //                         >
    //                         <i className="fa fa-edit mr-0"></i>
    //                     </button>
    //                     {/* <button
    //                         className="btn btn-danger btn-sm"
    //                         onClick={() => uploadImage(record)}>
    //                         <i className="fa fa-edit mr-0"></i>
    //                     </button> */}
    //                     {/* <button
    //                         className="btn btn-primary btn-sm"
    //                         onClick={() => WhiteList(record)}
    //                         style={{ marginRight: '5px' }}>
    //                         <i className="fa fa-edit mr-0"></i>
    //                     </button> */}
    //                 </Fragment>
    //             );
    //         }
    //     }
    // ];
    const guestusercolumn = [
        {
            key: "serial",
            text: "Id",
            className: "name",
            align: "left",
            sortable: true,
        },
        {
            key: "firstName",
            text: "Firstname",
            className: "name",
            align: "left",
            // sortable: true,
        },
        // {
        //     key: "lastName",
        //     text: "Lastname",
        //     className: "name",
        //     align: "left",
        //     // sortable: true,
        // },
        {
            key: "email",
            text: "Email",
            className: "name",
            align: "left",
            // sortable: true,
        },
        {
            key: "countrycode",
            text: "Country",
            className: "name",
            align: "left",
            // sortable: true,
        },
        {
            key: "isHost",
            text: "IsHost",
            className: "name",
            align: "left",
            sortable: true,
            cell: (data) => (data.isHost ? "Active" : "Inactive")
        },
        {
            key: "loginType",
            text: "Login Type",
            className: "name",
            align: "left",
            // sortable: true,
        },
        {
            key: "status",
            text: "Status",
            className: "name",
            align: "left",
            sortable: true,
        },
        {
            key: "createdAt",
            text: "Created At",
            className: "name",
            align: "left",
            sortable: true,
            cell: (data) =>(DateTimeForm(String(data?.createdAt))?.split(",")[0]  + "   " + DateTimeForm(String(data?.createdAt))?.split(",")[1])
        },
        {
            key: "updatedAt",
            text: "Updated At",
            className: "name",
            align: "left",
            sortable: true,
            cell: (data) =>(DateTimeForm(String(data?.updatedAt))?.split(",")[0]  + "   " + DateTimeForm(String(data?.updatedAt))?.split(",")[1])
        },
        // {
        //     key: "action",
        //     text: "Edit action",
        //     className: "action",
        //     width: 100,
        //     align: "left",
        //     sortable: true,
        //     cell: record => {
        //         return (
        //             <Fragment>
        //                 {/* <button
        //                     className="btn btn-primary btn-sm"
        //                     // onClick={() => viewRecord(record)}
        //                     style={{ marginRight: '5px' }}>
        //                     <i class="fa fa-eye mr-0" ></i>
        //                 </button> */}
        //                 <button
        //                     className="btn btn-primary btn-sm"
        //                     // onClick={() => editRecord(record)}
        //                     // style={{ marginRight: '5px' }}
        //                     >
        //                     <i className="fa fa-edit mr-0"></i>
        //                 </button>
        //                 <button
        //                     className="btn btn-primary btn-sm"
        //                     // onClick={() => deactivateuser(record)}
        //                     >
        //                     <i className="fa fa-trash"></i>
        //                 </button>
        //                 {/* <button
        //                     className="btn btn-primary btn-sm"
        //                     onClick={() => WhiteList(record)}
        //                     style={{ marginRight: '5px' }}>
        //                     <i className="fa fa-edit mr-0"></i>
        //                 </button> */}
        //             </Fragment>
        //         );
        //     }
        // }
    ];

    const hostusercolumn = [
        {
            key: "serial",
            text: "Id",
            className: "name",
            align: "left",
            sortable: true,
        },
        {
            key: "firstName",
            text: "Firstname",
            className: "name",
            align: "left",
            // sortable: true,
        },
        // {
        //     key: "lastName",
        //     text: "Lastname",
        //     className: "name",
        //     align: "left",
        //     // sortable: true,
        // },
        {
            key: "email",
            text: "Email",
            className: "name",
            align: "left",
            // sortable: true,
        },

        {
            key: "countrycode",
            text: "Country",
            className: "name",
            align: "left",
            // sortable: true,
        },
        {
            key: "isHost",
            text: "IsHost",
            className: "name",
            align: "left",
            sortable: true,
            cell: (data) => (data.isHost ? "Active" : "Inactive")
        },
        {
            key: "loginType",
            text: "Login Type",
            className: "name",
            align: "left",
            // sortable: true,
        },
        {
            key: "status",
            text: "Status",
            className: "name",
            align: "left",
            sortable: true,
        },
        {
            key: "createdAt",
            text: "Created At",
            className: "name",
            align: "left",
            sortable: true,
            cell: (data) =>(DateTimeForm(String(data?.createdAt))?.split(",")[0]  + "   " + DateTimeForm(String(data?.createdAt))?.split(",")[1])
        },
        {
            key: "updatedAt",
            text: "Updated At",
            className: "name",
            align: "left",
            sortable: true,
            cell: (data) =>(DateTimeForm(String(data?.updatedAt))?.split(",")[0]  + "   " + DateTimeForm(String(data?.updatedAt))?.split(",")[1])
        },
        // {
        //     key: "action",
        //     text: "Edit action",
        //     className: "action",
        //     width: 100,
        //     align: "left",
        //     sortable: true,
        //     cell: record => {
        //         return (
        //             <Fragment>
        //                 {/* <button
        //                     className="btn btn-primary btn-sm"
        //                     // onClick={() => viewRecord(record)}
        //                     style={{ marginRight: '5px' }}>
        //                     <i class="fa fa-eye mr-0" ></i>
        //                 </button> */}
        //                 <button
        //                     className="btn btn-primary btn-sm"
        //                     // onClick={() => editRecord(record)}
        //                     // style={{ marginRight: '5px' }}
        //                     >
        //                     <i className="fa fa-edit mr-0"></i>
        //                 </button>
        //                 <button
        //                     className="btn btn-primary btn-sm"
        //                     // onClick={() => deactivateuser(record)}
        //                     >
        //                     <i className="fa fa-trash"></i>
        //                 </button>
        //                 {/* <button
        //                     className="btn btn-primary btn-sm"
        //                     onClick={() => WhiteList(record)}
        //                     style={{ marginRight: '5px' }}>
        //                     <i className="fa fa-edit mr-0"></i>
        //                 </button> */}
        //             </Fragment>
        //         );
        //     }
        // }

    ]

    const bookingcolumn = [
        {
            key: "serial",
            text: "S.no",
            className: "name",
            align: "left",
            sortable: true,

        },
        {
            key: "email",
            text: "Guest Email",
            className: "name",
            align: "left",
            // sortable: true,
        },
        {
            key: "hostEmail",
            text: "Host Email",
            className: "name",
            align: "left",
            // sortable: true,
        },
        {
            key: "roomTitle",
            text: "Room Title",
            className: "name",
            align: "left",
            // sortable: true,
        },
        {
            key: "checkInDate",
            text: "Checkin Date",
            className: "name",
            align: "left",
            sortable: true,
            cell: (data) => (data?.checkInDate?.slice(0, 10))
        },
        {
            key: "checkOutDate",
            text: "Checkout Date",
            className: "name",
            align: "left",
            sortable: true,
            cell: (data) => (data?.checkOutDate?.slice(0, 10))
        },
        {
            key: "totalAmount",
            text: "Total Amount",
            className: "name",
            align: "left",
            sortable: true,
        },
        {
            key: "nights",
            text: "Nights",
            className: "name",
            align: "left",
            sortable: true,
        },
        {
            key: "serviceFeeAmount",
            text: "Service Fee",
            className: "name",
            align: "left",
            sortable: true,
        },
        {
            cell: (data) => (data?.isVerified && data?.iscancelled || !data?.isVerified && data?.iscancelled ? "Cancelled" : !data?.isVerified && !data?.isRejected && !data?.iscancelled && !data?.isValidTransaction ? "Pending" : data?.isRejected ? "Rejected" : data?.isVerified && "Approved"),
            key: "isVerified",
            text: "Status",
            className: "name",
            align: "left",

            sortable: true,
        },
        {
            key: "createdAt",
            text: "Created At",
            className: "name",
            align: "left",
            sortable: true,
            cell: (data) =>(DateTimeForm(String(data?.createdAt))?.split(",")[0]  + "   " + DateTimeForm(String(data?.createdAt))?.split(",")[1])
        }
    ]

    const roomcolumn = [
        {
            key: "serial",
            text: "Id",
            className: "name",
            align: "left",
            sortable: true,
        },
        {
            key: "title",
            text: "Room Name",
            className: "name",
            align: "left",
            // sortable: true,
        },
        {
            key: "firstName",
            text: "First Name",
            className: "name",
            align: "left",
            // sortable: true,
        },
        {
            key: "lastName",
            text: "Last Name",
            className: "name",
            align: "left",
            // sortable: true,
        },
        {
            key: "privacyType",
            text: "Privacy Type",
            className: "name",
            align: "left",
            // sortable: true,
        },
        {
            key: "email",
            text: "Email",
            className: "name",
            align: "left",
            // sortable: true,
        },
        {
            key: "price",
            text: "Price",
            className: "name",
            align: "left",
            sortable: true,
        },
        {
            key: "cancellationPolicy",
            text: "Cancellation Policy",
            className: "name",
            align: "left",
            // sortable: true,
        },
        {
            key: "status",
            text: "Status",
            className: "name",
            align: "left",
            sortable: true,
            cell: (data) => (data.isdeactivate ? "Inactive" : "Active")
        },
        {
            key: "isDeactivate",
            text: "Deactivate Status",
            className: "name",
            align: "left",
            sortable: true,
            cell: (data) => (data.isDeactivate ? "true" : "false")
        },
        {
            key: "createdAt",
            text: "Created At",
            className: "name",
            align: "left",
            sortable: true,
            cell: (data) =>(DateTimeForm(String(data?.createdAt))?.split(",")[0]  + "   " + DateTimeForm(String(data?.createdAt))?.split(",")[1])
        },
        {
            key: "updatedAt",
            text: "Updated At",
            className: "name",
            align: "left",
            sortable: true,
            cell: (data) =>(DateTimeForm(String(data?.updatedAt))?.split(",")[0]  + "   " + DateTimeForm(String(data?.updatedAt))?.split(",")[1])
        },
        // {
        //     key: "action",
        //     text: "Edit action",
        //     className: "action",
        //     width: 100,
        //     align: "left",
        //     sortable: true,
        //     cell: record => {
        //         return (
        //             <Fragment>
        //                 {/* <button
        //                     className="btn btn-primary btn-sm"
        //                     // onClick={() => viewRecord(record)}
        //                     style={{ marginRight: '5px' }}>
        //                     <i class="fa fa-eye mr-0" ></i>
        //                 </button> */}
        //                 <button
        //                     className="btn btn-primary btn-sm"
        //                     // onClick={() => editroom(record)}
        //                     // style={{ marginRight: '5px' }}
        //                     >
        //                     <i className="fa fa-edit mr-0"></i>
        //                 </button>
        //                 <button
        //                     className="btn btn-primary btn-sm"
        //                     // onClick={() => deactivateroom(record)}
        //                     >
        //                     <i className="fa fa-trash mr-0"></i>
        //                 </button>
        //                 {/* <button
        //                     className="btn btn-primary btn-sm"
        //                     onClick={() => WhiteList(record)}
        //                     style={{ marginRight: '5px' }}>
        //                     <i className="fa fa-edit mr-0"></i>
        //                 </button> */}
        //             </Fragment>
        //         );
        //     }
        // }
    ]

    const experiencecolumn = [
        {
            key: "serial",
            text: "Id",
            className: "name",
            align: "left",
            sortable: true,
        },
        {
            key: "title",
            text: "Title",
            className: "name",
            align: "left",
            // sortable: true,
        },
        {
            key: "email",
            text: "Host email",
            className: "name",
            align: "left",
            // sortable: true,
        },
        {
            key: "startTime",
            text: "Start Date",
            className: "name",
            align: "left",
            sortable: true,
        },
        {
            key: "endTime",
            text: "End Date",
            className: "name",
            align: "left",
            sortable: true,
        },
        {
            key: "pricePerGuest",
            text: "Price Per Head",
            className: "name",
            align: "left",
            sortable: true,
        },
        {
            key: "status",
            text: "Status",
            className: "name",
            align: "left",
            sortable: true,
        },
        {
            key: "isDeactivate",
            text: "Is Deactivate",
            className: "name",
            align: "left",
            sortable: true,
            cell: (data) => (data.isDeactivate ? "true" : "false")
        },

        // {
        //     key: "action",
        //     text: "Edit action",
        //     className: "action",
        //     width: 100,
        //     align: "left",
        //     sortable: true,
        //     cell: record => {
        //         return (
        //             <Fragment>
        //                 {/* <button
        //                     className="btn btn-primary btn-sm"
        //                     // onClick={() => viewRecord(record)}
        //                     style={{ marginRight: '5px' }}>
        //                     <i class="fa fa-eye mr-0" ></i>
        //                 </button> */}
        //                 <button
        //                     className="btn btn-primary btn-sm"
        //                     // onClick={() => editRecord(record)}
        //                     style={{ marginRight: '5px' }}
        //                     >
        //                     <i className="fa fa-edit mr-0"></i>
        //                 </button>
        //                 {/* <button
        //                     className="btn btn-danger btn-sm"
        //                     onClick={() => uploadImage(record)}>
        //                     <i className="fa fa-edit mr-0"></i>
        //                 </button> */}
        //                 <button
        //                     className="btn btn-primary btn-sm"
        //                     // onClick={() => deactivateexperience(record)}
        //                     style={{ marginRight: '5px' }}>
        //                     <i className="fa fa-trash mr-0"></i>
        //                 </button>
        //             </Fragment>
        //         );
        //     }
        // }
    ]

    const columns = category == "guestusers" ? guestusercolumn : category == "hostusers" ? hostusercolumn
        : category == "bookings" ? bookingcolumn : category == "rooms" ? roomcolumn
            : category == "experiences" ? experiencecolumn : "";

    const data = category == "guestusers" ? guestuser : category == "hostusers" ? hostuser
        : category == "bookings" ? booking : category == "rooms" ? rooms
            : category == "experiences" ? experience : "";

    { console.log("column", columns, data) }
    const config = {
        page_size: 10,
        length_menu: [10, 20, 50],
        filename: "Users",
        no_data_text: 'No user found!',
        button: {
            excel: true,
            print: true,
            csv: true
        }, className: "table",
        language: {
            length_menu: "Show _MENU_",
            filter: "Filter User",
            info: "Showing _START_ to _END_ of _TOTAL_ records",
            pagination: {
                first: "<<",
                previous: "<",
                next: ">",
                last: ">>"
            }
        },
        show_length_menu: true,
        show_filter: true,
        show_pagination: true,
        show_info: true,
    };
    // const config2 = {
    //     page_size: 10,
    //     length_menu: [ 10, 20, 50 ],
    //     filename: "Users",
    //     no_data_text: 'Loading data..',
    //     button: {
    //         excel: true,
    //         print: true,
    //         csv: true
    //     },
    //     language: {
    //         length_menu: "Show _MENU_",
    //         filter: "Filter User",
    //         info: "Showing _START_ to _END_ of _TOTAL_ records",
    //         pagination: {
    //             previous: "Previous",
    //             next: "Next",
    //             last: "Last"
    //         }
    //     },
    //     show_length_menu: true,
    //     show_filter: true,
    //     show_pagination: true,
    //     show_info: true,
    // };



    const pageChange = async (index) => {

        try {
            console.log('category_pageChange', index,category,category == "bookings" && !from?.length && !to?.length);
            let reqData = {
                page: index.page_number,
                limit: index.page_size,
                search: index.filter_value
            }
            if (category == "guestusers" && !from?.length && !to?.length) {

                await getallguestuserhook(reqData).then((result) => {
                    console.log("get all guestuser result", result);
                    result?.data?.data?.record?.map((data, i) => data.serial = i + 1)
                    setGuestuser(result?.data?.data?.record);
                    setTotalRecordCounts(result?.data?.data?.count);
                })
            }

            if (category == "guestusers" && from?.length > 0 && to?.length > 0) {
                if (from <= to) {
                    let payload = {
                        startdate: from,
                        enddate: to,
                        type: "both"
                    };
                    console.log("paayload", payload);
                    await filterguestuserhook(payload).then((result) => {
                        console.log("filter guest user hook result", result?.data?.record);
                        result?.data?.record?.map((data, i) => data.serial = i + 1)
                        setGuestuser(result?.data?.record);
                        setTotalRecordCounts(result?.data?.count)
                    })
                }
                else {
                    window.alert("You must select to date greater than or equal to from date !..");
                    setTo('');
                }
            }
            if (category == "guestusers" && from?.length > 0 && !to?.length) {
                let payload = {
                    startdate: from,
                    type: "from"
                };
                console.log("paayload", payload);
                await filterguestuserhook(payload).then((result) => {
                    console.log("filter guest user hook result", result);
                    result?.data?.record?.map((data, i) => data.serial = i + 1)
                    setGuestuser(result?.data?.record);
                    setTotalRecordCounts(result?.data?.count);
                })
            }

            if (category == "guestusers" && !from?.length && to?.length > 0) {
                let payload = {
                    enddate: to,
                    type: "to"
                };
                console.log("paayload", payload);
                await filterguestuserhook(payload).then((result) => {
                    console.log("filter guest user hook result", result);
                    result?.data?.record?.map((data, i) => data.serial = i + 1)
                    setGuestuser(result?.data?.record);
                    setTotalRecordCounts(result?.data?.count);
                })
            }

            if (category == "hostusers" && from?.length > 0 && to?.length > 0) {
                if (from <= to) {
                    let payload = {
                        type: "both",
                        startdate: from,
                        enddate: to
                    }
                    console.log("payload", payload);
                    await filterhostuserhook(payload).then((result) => {
                        console.log("filter host user hook result", result);
                        result?.data?.record?.map((data, i) => data.serial = i + 1)
                        setHostuser(result?.data?.record);
                        setTotalRecordCounts(result?.data?.count)
                    })
                }
                else {
                    window.alert("You must select to date greaer than or equal to from date !..");
                    setTo('');
                }
            }
            if (category == "hostusers" && !from?.length && !to?.length) {

                await getallhostuserhook(reqData).then((result) => {
                    console.log("get all host user hook result", result);
                    result?.data?.data?.record?.map((data, i) => data.serial = i + 1)
                    setHostuser(result?.data?.data?.record);
                    setTotalRecordCounts(result?.data?.data?.count)
                })
            }
            if (category == "hostusers" && from?.length > 0 && !to?.length) {
                let payload = {
                    type: "from",
                    startdate: from,
                }
                console.log("payload", payload);
                await filterhostuserhook(payload).then((result) => {
                    console.log("filter host user hook result", result);
                    result?.data?.record?.map((data, i) => data.serial = i + 1)
                    setHostuser(result?.data?.record);
                    setTotalRecordCounts(result?.data?.count);
                })
            }
            if (category == "hostusers" && !from?.length && to?.length > 0) {
                let payload = {
                    type: "to",
                    enddate: to
                }
                console.log("payload", payload);
                await filterhostuserhook(payload).then((result) => {
                    console.log("filter host user hook result", result);
                    result?.data?.record?.map((data, i) => data.serial = i + 1)
                    setHostuser(result?.data?.record);
                    setTotalRecordCounts(result?.data?.count);
                })
            }
            if (category == "bookings" && from?.length > 0 && to?.length > 0) {
                if (from <= to) {
                    let payload = {
                        startdate: from,
                        enddate: to,
                        type: "both"
                    }
                    console.log("payload", payload);
                    await filterallbookinghook(payload).then((result) => {
                        console.log("filter all booking hook result", result);
                        result?.data?.record?.map((data, i) => data.serial = i + 1)
                        setBooking(result?.data?.record);
                        setTotalRecordCounts(result?.data?.count)
                    })
                }
                else {
                    window.alert("You must select to date greaer than or equal to from date !..");
                    setTo('');
                }
            }
            if (category == "bookings" && !from?.length && !to?.length) {
                await getallbookingshook(reqData).then((result) => {
                    console.log("get all booking hook result", result);
                    result?.data?.data?.record?.map((data, i) => data.serial = i + 1)
                    setBooking(result?.data?.data?.record);
                    setTotalRecordCounts(result?.data?.data?.count)
                })
            }
            if (category == "bookings" && from?.length > 0 && !to?.length) {
                let payload = {
                    startdate: from,

                    type: "from"
                }
                console.log("payload", payload);
                await filterallbookinghook(payload).then((result) => {
                    console.log("filter all booking hook result", result);
                    result?.data?.record?.map((data, i) => data.serial = i + 1)
                    setBooking(result?.data?.record);
                    setTotalRecordCounts(result?.data?.count);
                })
            }
            if (category == "bookings" && !from?.length && to?.length > 0) {
                let payload = {
                    enddate: to,
                    type: "to"
                }
                console.log("payload", payload);
                await filterallbookinghook(payload).then((result) => {
                    console.log("filter all booking hook result", result);
                    result?.data?.record?.map((data, i) => data.serial = i + 1)
                    setBooking(result?.data?.record);
                    setTotalRecordCounts(result?.data?.count);
                })
            }

            if (category == "rooms" && from?.length > 0 && to?.length > 0) {
                if (from <= to) {
                    let payload = {
                        startdate: from,
                        enddate: to,
                        type: "both"
                    }
                    console.log("payload", payload);
                    await filterallroomshook(payload).then((result) => {
                        console.log("filter all room hook result", result);
                        result?.data?.record?.map((data, i) => data.serial = i + 1)
                        setRooms(result?.data?.record);
                        setTotalRecordCounts(result?.data?.count);
                    })
                }
                else {
                    window.alert("You must select to date greater than or equal to from date !..");
                    setTo('');
                }
            }
            if (category == "rooms" && !from?.length && !to?.length) {
                await getallroomshook(reqData).then((result) => {
                    console.log("get all rooms hook result", result);
                    result?.data?.data?.record?.map((data, i) => data.serial = i + 1)
                    setRooms(result?.data?.data?.record);
                    setTotalRecordCounts(result?.data?.data?.count)
                })
            }
            if (category == "rooms" && from?.length > 0 && !to?.length) {
                let payload = {
                    startdate: from,
                    type: "from"
                }
                console.log("payload", payload);
                await filterallroomshook(payload).then((result) => {
                    console.log("filter all room hook result", result);
                    result?.data?.record?.map((data, i) => data.serial = i + 1)
                    setRooms(result?.data?.record);
                    setTotalRecordCounts(result?.data?.count);
                })
            }
            if (category == "rooms" && !from?.length && to?.length > 0) {
                let payload = {
                    enddate: to,
                    type: "to"
                }
                console.log("payload", payload);
                await filterallroomshook(payload).then((result) => {
                    console.log("filter all room hook result", result);
                    result?.data?.record?.map((data, i) => data.serial = i + 1)
                    setRooms(result?.data?.record);
                    setTotalRecordCounts(result?.data?.count);
                })
            }

            if (category == "experiences" && from?.length > 0 && to?.length > 0) {
                if (from <= to) {
                    let payload = {
                        type: "both",
                        startdate: from,
                        enddate: to
                    }
                    console.log("payload", payload);
                    await filterallexperienceshook(payload).then((result) => {
                        console.log("filter all experiences hook result", result);
                        setExperience(result?.data?.record);
                        setTotalRecordCounts(result?.data?.count);
                    })
                }
                else {
                    window.alert("You must select to date greaer than or equal to from date !..")
                    setTo('');
                }
            }
            if (category == "experiences" && !from?.length && !to?.length) {
                await gethostingexperiencehook(reqData).then((result) => {
                    console.log("get hosting experience hook result", result);
                    result?.data?.data?.record?.map((data, i) => data.serial = i + 1)
                    setExperience(result?.data?.data?.record);
                    setTotalRecordCounts(result?.data?.data?.count);
                })
            }
            if (category == "experiences" && from?.length > 0 && !to?.length) {
                let payload = {
                    type: "from",
                    startdate: from
                }
                console.log("payload", payload);
                await filterallexperienceshook(payload).then((result) => {
                    console.log("filter all experiences hook result", result);
                    setExperience(result?.data?.record);
                    setTotalRecordCounts(result?.data?.count);
                })
            }
            if (category == "experiences" && !from?.length && to?.length > 0) {
                let payload = {
                    type: "to",
                    enddate: to
                }
                console.log("payload", payload);
                await filterallexperienceshook(payload).then((result) => {
                    console.log("filter all experiences hook result", result);
                    setExperience(result?.data?.record);
                    setTotalRecordCounts(result?.data?.count);
                })
            }
            console.log('index_data', index, category)
        } catch (err) {
            console.log("pageChange_err", err)
        }
    }

    

    const handlePerRowsChange = async (newPerPage, page) => {
        try{
console.log('newPerPage, page',newPerPage, page)
        } catch (e) {
            console.log('handlePerRowsChange_err', e)
        }
    }


    return (
        <div id="dashboard" className='showsidebar'>
            <div id='sidebar'>
                <Sidebar />
            </div>
            <div className='headandcont'>
                <div className="adminheader"><AdminHeader /></div>
                <div className='cont'>
                    <h4 className='adminblue my-4'>Reports</h4>
                    <div className='admin-forms mb-4 '>
                        <div className='shadowbox p-4 br-10'>
                            <div className='row ' >
                                <div className='col-xl-4 col-md-6 mb-4'>
                                    <FloatingLabel controlId="floatingSelect" label="From Date">
                                        <Form.Control type="date" className='' value={from?.slice(0, 10)} onChange={(e) => { setFrom(e?.target?.value) }} />
                                    </FloatingLabel>
                                </div>
                                <div className='col-xl-4 col-md-6 mb-4'>
                                    <FloatingLabel controlId="floatingSelect" label="To Date">
                                        <Form.Control type="date" className='' value={to?.slice(0, 10)} onChange={(e) => { setTo(e?.target?.value) }} />
                                    </FloatingLabel>
                                </div>
                                <div className='col-xl-4 col-md-6 mb-4'>
                                    <FloatingLabel controlId="floatingSelect" label="Category">
                                        <Form.Select aria-label="Floating label select example" value={category} onChange={(e) => { setCategory(e?.target?.value) }}>
                                            {/* <option value ="">Select Category</option> */}
                                            <option >guestusers</option>
                                            <option >hostusers</option>
                                            <option>bookings</option>
                                            <option>rooms</option>
                                            <option>experiences</option>
                                        </Form.Select>
                                    </FloatingLabel>
                                </div>



                            </div>

                            {/* <div className='px-4 text-end mt-3' >
                            <button className='btn btn-theme me-3'>Submit</button>
                            <Button variant="outline-dark" className='f-12 fw-600 outlinebtn'>Cancel</Button>
                        </div> */}
                            <div className='tablesec shadowbox px-3 mt-3 br-10 mb-5'>
                                <h6 className='adminblue pt-4 me-2' ><span className='fa fa-book'></span> View Report</h6>
                                {/* columns, data, totalrecordcounts, */}
                                {console.log('columns_data', columns, data, totalrecordcounts)}
                                {/* <DataTable pagination columns={columns} records={data} config = {config} className={config.className}/> */}
                                <DataTable striped responsive
                                    config={config}
                                    className={config.className}
                                    records={data}
                                    columns={columns}
                                    dynamic={true}
                                    // pagination='basic'
                                    total_record={totalrecordcounts}
                                    onChange={pageChange}
                                    onChangeRowsPerPage={handlePerRowsChange}

                                />
                            </div>
                        </div>

                    </div>


                </div>
            </div>
        </div>
    )
}

export default FormView