const BASE_URL = "admin";
const PAYMENT_BASE_URL = "api"

// export const getHostDetailsApi = BASE_URL + "/get-host-details";
// export const getHostedDatasApi = BASE_URL + "/get-hosted-datas";
// export const getSingleHostingDetailApi = BASE_URL + "/get-single-hosting-detail";
// export const getAdminDetailApi = BASE_URL + "/get-admin-details";
export const adminloginApi = BASE_URL + "/admin-login";
export const getalladminApi = BASE_URL + "/get-all-admin";
export const gettotalbookingApi = BASE_URL + "/get-total-bookingcount";
export const gettotalcustomerApi = BASE_URL + "/get-total-customercount";
export const gettotalguestuserApi = BASE_URL + "/get-total-guestusercount";
export const gettotalhostuserApi = BASE_URL + "/get-total-hostusercount";
export const getnewbookingsApi = BASE_URL + "/get-new-bookingscount";
export const getnewcustomerApi = BASE_URL + "/get-new-customercount";
export const getnewguestuserApi = BASE_URL + "/get-new-guestusercount";
export const getnewhostuserApi = BASE_URL + "/get-new-hostusercount";
export const getalluserApi = BASE_URL + "/get-all-users";
export const filterdateuserApi = BASE_URL + "/filter-day-user";
export const editadminApi = BASE_URL + "/edit-admin";
export const addadminApi = BASE_URL + "/add-admin";
export const deactivateadminApi = BASE_URL + "/deactivate-admin";
export const edituserApi = BASE_URL + "/edit-user";
export const deactivateuserApi = BASE_URL + "/deactivateuser";
export const getallroomsApi = BASE_URL + "/get-all-rooms";
export const deactivateroomApi = BASE_URL + "/deactivate-room";
export const acceptuserverificationApi = BASE_URL + "/accept-user-verifiation";
export const rejectuserverificationApi = BASE_URL + "/reject-user-verification";
export const getallbookingApi = BASE_URL + "/get-all-bookings";
export const approveroomApi = BASE_URL + "/update-approve-details";
export const rejectroomApi = BASE_URL + "/update-reject-details";
export const getcancelledbookingApi = BASE_URL + "/get-cancelled-booking";
export const getallhostuserApi = BASE_URL + "/get-all-host-users";
export const getallfloorplanApi = BASE_URL + "/get-floorplan";
export const addnewfloorplanApi = BASE_URL + "/add-floorplan";
export const editfloorplanApi = BASE_URL + "/edit-floorplan";
export const deletefloorplanApi = BASE_URL + "/delete-floorplan";
export const getpropertytypeApi = BASE_URL + "/get-propertytype";
export const addpropertytypeApi = BASE_URL + "/add-new-propertytype";
export const editpropertytypeApi = BASE_URL + "/edit-propertytype";
export const deletepropertytypeApi = BASE_URL + "/delete-propertytype";
export const getprivacytypeApi = BASE_URL + "/get-privacytype";
export const getlegalApi = BASE_URL + "/get-legal";
export const editlegalprivacyApi = BASE_URL + "/edit-legal-privacy";
export const addlegalprivacyApi = BASE_URL + "/add-legal-privacy";
export const deletelegalprivacyApi = BASE_URL + "/delete-legalandprivacy";
export const getpropertytypegroupApi = BASE_URL + "/get-propertytypegroup";
export const getdescriptionApi = BASE_URL + "/get-description";
export const getamenitiesApi = BASE_URL + "/get-amenities";
export const addamenitytypeApi = BASE_URL + "/add-amenitytype";
export const editamenitytypeApi = BASE_URL + "/edit-amenitytype";
export const deleteamenitytypeApi = BASE_URL + "/delete-amenitytype";
export const getcancellationpolicyApi = BASE_URL + "/get-cancellation-policy";
export const addcancellationpolicyApi = BASE_URL + "/add-cancellation-policy";
export const editcancellationpolicyApi = BASE_URL + "/edit-cancellation-policy";
export const deletecancellationpolicyApi = BASE_URL + "/delete-cancellation-policy";
export const getalldisputeApi = BASE_URL + "/view-all-disputes";
export const getallcrmApi = BASE_URL + "/get-all-crm";
export const updatedisputeApi = BASE_URL + "/assigned-or-update-dispute";
export const getallemailtemplateApi =  BASE_URL + "/get-all-emailtemplates";
export const deactivateemailtemplateApi = BASE_URL + "/deactivateemailtemplate";
export const editemailtemplateApi = BASE_URL + "/edit-emailtemplates";
export const deactivatesubscription = BASE_URL + "/deactivatesubscription";

export const closeddisputemailApi = BASE_URL + "/closed-dispute-mail";
export const getallcouponApi = BASE_URL + "/get-all-coupon";
export const addcouponApi = BASE_URL + "/add-coupon";
export const editcouponApi = BASE_URL + "/edit-coupon";
export const deletecouponApi = BASE_URL + "/delete-coupon";
export const getsitesettingApi = BASE_URL + "/get-sitesetting";
export const updatesitesettingApi = BASE_URL + "/update-site-setting";
export const getemailsettingApi = BASE_URL + "/get-emailsetting";
export const updateemailsettingApi = BASE_URL + "/update-email-setting";
export const getjoinusApi = BASE_URL + "/get-joinus";
export const updatejoinusApi = BASE_URL + "/update-joinus";
export const activateroomApi = BASE_URL + "/activate-room";
export const getexperiencedetailApi = BASE_URL + "/get-experience-details";
export const updateexperiencedetailApi = BASE_URL + "/update-experience-details";
export const addexperiencedetailApi = BASE_URL + "/add-experience-detail";
export const deactivateexperiencedetailApi = BASE_URL + "/deactivate-experience-details";
export const gethostingexperienceApi = BASE_URL + "/get-hosting-experience";
export const approvehostingexperienceApi = BASE_URL + "/approve-experience";
export const rejecthostingexperienceApi = BASE_URL + "/reject-experience";
export const deactivatehostingexperienceApi = BASE_URL + "/deactivate-experience";
export const reactivatehostingexperienceApi = BASE_URL + "/reactivate-experience";
export const disputechatApi = BASE_URL + "/dispute-chat";
export const getchatApi = BASE_URL + "/get-chat";
export const getpaymentgatewayApi = BASE_URL + "/get-payment-gateway";
export const updatepaymentgatewayApi = BASE_URL + "/update-payment-gateway";
export const getallguestuserApi = BASE_URL + "/get-all-guestuser";
export const filterguestuserApi = BASE_URL + "/filter-guestuser";
export const filterhostuserApi = BASE_URL + "/filter-hostuser";
export const filterallbookingApi = BASE_URL + "/filter-all-bookings";
export const filterallroomsApi = BASE_URL + "/filter-all-rooms";
export const filterallexperienceApi = BASE_URL + "/filter-all-experiences";
export const getfeeApi = BASE_URL + "/get-service-fee";
export const updatefeeApi = BASE_URL + "/update-service-fee";
export const addfeeApi = BASE_URL + "/add-fee";
export const deactivatefeeApi = BASE_URL + "/deactivate-fee";
export const getapicredentialApi = BASE_URL + "/get-api-credential";
export const updateapicredentialApi = BASE_URL + "/update-apicredential";
export const addfaqApi = BASE_URL + "/add-faq";

export const getsinglehostuserroomcountApi = BASE_URL + "/get-singlehost-user-roomcount";
export const getsinglehostuserroomsApi = BASE_URL + "/get-singlehost-user-rooms";
export const getsinglehostuserlistedroomsApi = BASE_URL + "/get-singlehost-user-listedrooms";
export const getsinglehostuserlistedroomcountApi = BASE_URL + "/get-singlehost-user-listedroomcounts";
export const getsinglehostuserunlistedroomApi = BASE_URL + "/get-singlehost-user-unlistedrooms";
export const getsinglehostuserunlistedroomcountApi = BASE_URL + "/get-single-host-user-unlistedroomcount";
export const getsinglehostusertotalbookingApi = BASE_URL + "/get-singlehost-user-totalbooking";
export const getsinglehostusertotalbookingcountApi = BASE_URL + "/get-single-host-user-totalbookingcount";
export const getsinglehostuseracceptedbookingApi = BASE_URL + "/get-singlehost-user-acceptedbooking";
export const getsinglehostuseracceptedbookingcountApi = BASE_URL + "/get-single-host-user-acceptedbookingcount";
export const getsinglehostuserrejectedbookingApi = BASE_URL + "/get-singlehost-user-rejectedbooking";
export const getsinglehostuserrejectedbookingcountApi = BASE_URL + "/get-singlehost-user-rejectedbookingcount";
export const getsinglehostusercancelledbookingApi = BASE_URL + "/get-singlehost-user-cancelledbooking";
export const getsinglehostusercancelledbookingcountApi = BASE_URL + "/get-single-host-user-cancelledbookingcount";
export const getsinglehostuserexpiredbookingApi = BASE_URL + "/get-singlehost-user-expiredbooking";
export const getsinglehostuserexpiredbookingcountApi = BASE_URL + "/get-singlehost-user-expiredbookingcount";
export const getsinglehostuserpendingbookingApi = BASE_URL + "/get-singlehost-user-pendingbooking";
export const getsinglehostuserpendingbookingcountApi = BASE_URL + "/get-singlehostuser-pendingbookingcount";
export const getsinglehostuserpreapprovedbookingApi = BASE_URL + "/get-singlehost-user-preapprovedbooking";
export const getsinglehostuserpreapprovedbookingcountApi = BASE_URL + "/get-singlehostuser-preapprovedbookingcount";
export const filtersinglehostuserroomsApi = BASE_URL + "/filter-singlehost-user-rooms";
export const filtersinglehostuserlistedroomsApi = BASE_URL + "/filter-singlehost-user-listedrooms";
export const filtersinglehostuserunlistedroomsApi = BASE_URL + "/filter-singlehost-user-unlistedrooms"
export const filtersinglehostuserbookingApi = BASE_URL + "/filter-singlehost-user-totalbooking";
export const filtersinglehostuseracceptedbookingApi = BASE_URL + "/filter-singlehost-user-acceptedbooking";
export const filtersinglehostuserrejectedbookingApi = BASE_URL + "/filter-singlehost-user-rejectedbooking";
export const filtersinglehostusercancelledbookingApi = BASE_URL + "/filter-singlehost-user-cancelledbooking";
export const filtersinglehostuserexpiredbookingApi = BASE_URL + "/filter-singlehost-user-expiredbooking";
export const filtersinglehostuserpreapprovedbookingApi = BASE_URL + "/filter-singlehost-user-preapprovedbooking";
export const filtersinglehostuserpendingbookingApi = BASE_URL +"/filter-singlehost-user-pendingbooking";
export const getadminearningApi = BASE_URL + "/get-admin-earning";
export const filteradminearningApi = BASE_URL + "/filter-admin-earning";
export const getsinglehostearningApi = BASE_URL + "/get-single-host-earning";
export const filtersinglehostearningApi = BASE_URL + "/filter-single-host-earning";
export const sendmailApi = BASE_URL + "/send-mail";
export const forgotpasswordmailApi = BASE_URL + "/forgot-password-mail";
export const forgotpasswordApi = BASE_URL + "/forgot-password";
export const getcategoryApi = BASE_URL + "/get-category";
export const getsinglecrmdisputeApi = BASE_URL + "/get-singlecrm-dispute";
export const acceptthedisputebycrmApi = BASE_URL + "/accept-the-dispute-by-crm";
export const getcmsApi = BASE_URL + "/get-cms";
export const editcmsApi = BASE_URL + "/editcms";
export const deactivatecmsApi = BASE_URL + "/deactivatcms";
export const addcmsApi = BASE_URL + "/addcms";
export const getfaqcmsApi = BASE_URL + "/get-faqcms";
export const editfaqApi = BASE_URL + "/editfaq";
export const deactivateaboutcmsApi = BASE_URL + "/deactivataboutcms";
//payment integration
export const getwithdrawApi = BASE_URL + "/withdraw";
export const createwithdrawpaymentApi = PAYMENT_BASE_URL + "/create-withdraw-payment";
export const verifywithdrawpaymentApi = PAYMENT_BASE_URL + "/verify-withdraw-payment";
export const manualwithdrawpaymentApi = PAYMENT_BASE_URL + "/manual-withdraw-payment";

//experience finance report
export const getsinglehosttotalexperienceApi = BASE_URL + "/get-single-host-total-experience";
export const getsinglehosttotalexperiencecountApi = BASE_URL + "/get-single-host-total-experience-count";
export const filtersinglehosttotalexperienceApi = BASE_URL + "/filter-single-host-totalexperience";
export const getsinglehostapprovedexperienceApi = BASE_URL + "/get-single-host-approved-experience";
export const getsinglehostapprovedexperiencecountApi = BASE_URL + "/get-singlehost-approved-experience-count";
export const filtersinglehostapprovedexperienceApi = BASE_URL + "/filter-singlehost-approved-experience";
export const getsinglehostpendingexperienceApi = BASE_URL + "/get-singlehost-pending-experience";
export const getsinglehostpendingexperiencecountApi = BASE_URL + "/get-singlehost-pending-experience-count";
export const filtersinglehostpendingexperienceApi = BASE_URL + "/filter-singlehost-pending-experience";
export const getsinglehostrejectedexperienceApi = BASE_URL + "/get-singlehost-rejected-experience";
export const getsinglehostrejectedexperiencecountApi = BASE_URL + "/get-singlehost-rejected-experience-count";
export const filtersinglehostrejectedexperienceApi = BASE_URL + "/filter-singlehost-rejected-experience";
export const getsinglehosttotalexperiencebookingApi = BASE_URL + "/get-singlehost-total-experiencebooking";
export const getsinglehosttotalexperiencebookingcountApi = BASE_URL + "/get-singlehost-total-experiencebooking-count";
export const filtersinglehosttotalexperiencebookingApi= BASE_URL + "/filter-singlehost-total-experiencebooking";
export const getsinglehostcancelledexperienceApi = BASE_URL + "/get-singlehost-cancelled-experience";
export const getsinglehostcancelledexperiencecountApi = BASE_URL + "/get-singlehost-cancelled-experience-count";
export const filtersinglehostcancelledexperienceApi = BASE_URL + "/filter-singlehost-cancelled-experience";
export const getsinglehostexperienceadminearningApi = BASE_URL + "/get-singlehost-experience-admin-earning";
export const filtersinglehostexperienceadminearningApi = BASE_URL + "/filter-singlehost-experience-admin-earning";
export const getsinglehostexperienceearningApi = BASE_URL + "/get-singlehost-experience-earning";
export const fitersinglehostexperienceearningApi = BASE_URL + "/filter-singlehost-experience-earning";
export const getallexperiencebookingApi = BASE_URL + "/get-all-experience-booking";
export const getallcancelbookingroomApi = BASE_URL + "/get-cancel-rooms";
export const csvfloorfileuploadApi = BASE_URL + "/get-csvfloorfileupload";
export const csvpropertyfileuploadApi = BASE_URL + "/get-csvpropertyfileupload";
export const csvdescriptionfileuploadApi = BASE_URL + "/get-csvdescriptionfileupload";
export const csvlegalfileuploadApi = BASE_URL + "/get-csvlegalfileupload";
export const csvamenitiesfileuploadApi = BASE_URL + "/get-csvamenitiesfileupload";
export const csvcategoryfileuploadApi = BASE_URL + "/get-csvcategoryfileupload";

export const getsubscriptionApi = BASE_URL + "/getsubscription";
export const addsubscriptionApi = BASE_URL + "/addsubscription";
export const editsubscriptionApi = BASE_URL + "/editsubscription";

export const getstaydateApi = BASE_URL + "/get-staydate";
export const updatestaydateApi = BASE_URL + "/update-staydate";

export const admininstantbookingApi = BASE_URL + "/admin-instantbooking";
export const getsplitdaysApi = BASE_URL + "/getsplitpamentdays";
export const editsplitdaysApi = BASE_URL + "/editsplitpamentdays";
export const changebankdetailstatusApi = BASE_URL + "/changebankdetailstatus";
export const getcontactusApi = BASE_URL + "/getcontactus";

export const getlandcmsApi = BASE_URL + "/get-landcmsdata"
export const editlandcmsApi = BASE_URL + "/edit-landcmsdata";

export const getaboutcmsApi = BASE_URL + "/get-about-cms";
export const editaboutcmsApi = BASE_URL + "/edit-about-cms";

