import axios from "axios";
import Cookies from 'universal-cookie';
import * as api from '../config/adminRouteFront';
import { API_URL } from '../config/env';
const cookies = new Cookies();
const headers = {
  'Content-Type': 'application/json',
  // 'Authorization': cookies.get('accessToken')?`Bearer ${cookies.get('accessToken')}`:((cookies.get('User-Datas'))?`Bearer ${(cookies.get('User-Datas')).token}`:"")
  'Authorization': cookies.get('admin-Datas') && `Bearer ${(cookies.get('admin-Datas'))?.refreshtoken}`
}
const headers1 = {
  'Content-Type': 'multipart/form-data',
  // 'Authorization': cookies.get('accessToken')?`Bearer ${cookies.get('accessToken')}`:((cookies.get('User-Datas'))?`Bearer ${(cookies.get('User-Datas')).token}`:"")
  'Authorization': cookies.get('admin-Datas') && `Bearer ${(cookies.get('admin-Datas'))?.refreshtoken}`
};

export const loginhook = async (data) => {
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data.email);
      formdata.append("password", data?.password);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.adminloginApi,
        'credentials': true,
        'headers': {
          'content-Type': 'multipart/form-data',
        },
        data: formdata,
      });
      console.log("response", respdata);
      if (respdata?.data?.type == "success") {
        cookies.set('admin-email', respdata.data.record.email, { path: '/' }, { expires: new Date(Date.now() + 600000) });
        cookies.set('admin-Datas', respdata.data.record, { path: '/' }, { expires: new Date(Date.now() + 600000) })
      }
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const adminLogout = () => {
  cookies.remove("admin-email");
  cookies.remove("admin-Datas");
}

export const forgotpasswordmail = async (data) => {
  console.log("add content option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data.email)
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.getalladminApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}


export const forgotpassword = async (data) => {
  console.log("add content option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data.email);
      formdata.append("newpassword", data.newpassword);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        // 'url': config.backurl+"/admin/update-content",
        'credentials': true,
        'headers': {
          'content-Type': 'multipart/form-data',
        },
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const getalluserhook = async (data) => {

  try {
    console.log("headers", headers, data)
    let respData = await axios({
      'method': 'GET',
      'url': API_URL + api.getalluserApi,
      headers: headers,
      params : data

    });
    console.log("respdata", respData);
    return {
      data: respData
    }
  }
  catch (err) {
    return {
      error: err.response.data
    }
  }
}

export const filtersingledayuserhook = async (data) => {
  console.log("add content option data", data);
  var formdata = new FormData();
  try {

    if (data) {
      formdata.append("startdate", data);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.filterdateuserApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const addduserbyadmin = async (data) => {
  console.log("add content option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data?.email);
      formdata.append("countrycode", data?.countrycode);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        // 'url': config.backurl+"/admin/update-content",
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const edituserhook = async (data) => {
  console.log("add content option data");
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("id", data.id);
      formdata.append("status", data.status)
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.edituserApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const deactivateuserhook = async (data) => {
  console.log("add content option data");
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("id", data?.id);
      formdata.append("status", data?.status);
      // formdata.append("satus" , data.status)
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.deactivateuserApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const getalladmins = async (data) => {

  try {
    let respData = await axios({
      'method': 'GET',
      'url': API_URL + api.getalladminApi,
      headers: headers,
      params : data

    });
    console.log("respdata", respData);
    return {
      data: respData
    }
  }
  catch (err) {
    return {
      error: err.response.data
    }
  }
}

export const addadminhook = async (data) => {
  // console.log("add admin data" , data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("name", data?.name);
      formdata.append("email", data?.email);
      formdata.append("password", data?.password);
      formdata.append("level", data?.level);
      formdata.append("status", data?.status);
      formdata.append("iscms", data?.iscms);
      formdata.append("iscrm", data?.iscrm);
      formdata.append("ismanager", data?.ismanager);
      formdata.append("isreport", data?.isreport);
      formdata.append("issettings", data?.issettings);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.addadminApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const editadminhook = async (data) => {
  console.log("add content option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("id", data?.id);
      formdata.append("name", data?.name);
      formdata.append("email", data?.email);
      formdata.append("password", data?.password);
      formdata.append("level", data?.level);
      formdata.append("status", data?.status);
      formdata.append("iscms", data?.iscms);
      formdata.append("iscrm", data?.iscrm);
      formdata.append("ismanager", data?.ismanager);
      formdata.append("isreport", data?.isreport);
      formdata.append("issettings", data?.issettings);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.editadminApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const deactivateadminhook = async (data) => {
  console.log("add content option data");
  var formdata = new FormData();
  try {
    // if(data){
    formdata.append("id", data?.id);
    formdata.append("status", data?.status);
    //   formdata.append("name" , data?.name);
    //   formdata.append("email" , data?.email);
    //   formdata.append("password" , data?.password);
    //   formdata.append("level" , data?.level);
    //   formdata.append("status" , data?.status);
    //   formdata.append("iscms" , data?.iscms);
    //   formdata.append("iscrm" , data?.iscrm);
    //   formdata.append("ismanager" , data?.ismanager);
    //   formdata.append("isreport" , data?.isreport);
    //   formdata.append("issettings" , data?.issettings);
    // }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.deactivateadminApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const gettotalbookingcounthook = async (data) => {

  try {
    let respData = await axios({
      'method': 'GET',
      'url': API_URL + api.gettotalbookingApi,
      'headers': headers
    });
    console.log("respdata", respData);
    return {
      data: respData
    }
  }
  catch (err) {
    return {
      error: err.response.data
    }
  }
}

export const gettotalcustomercounthook = async (data) => {

  try {
    let respData = await axios({
      'method': 'GET',
      'url': API_URL + api.gettotalcustomerApi,
      'headers': headers
    });
    console.log("respdata", respData);
    return {
      data: respData
    }
  }
  catch (err) {
    return {
      error: err.response.data
    }
  }
}

export const gettotalguestusercounthook = async (data) => {

  try {
    let respData = await axios({
      'method': 'GET',
      'url': API_URL + api.gettotalguestuserApi,
      'headers': headers
    });
    console.log("respdata", respData);
    return {
      data: respData
    }
  }
  catch (err) {
    return {
      error: err.response.data
    }
  }
}

export const gettotalhostusercounthook = async (data) => {

  try {
    let respData = await axios({
      'method': 'GET',
      'url': API_URL + api.gettotalhostuserApi,
      'headers': headers
    });
    console.log("respdata", respData);
    return {
      data: respData
    }
  }
  catch (err) {
    return {
      error: err.response.data
    }
  }
}

export const getnewbookingcounthook = async (data) => {

  try {
    let respData = await axios({
      'method': 'GET',
      'url': API_URL + api.getnewbookingsApi,
      'headers': headers
    });
    console.log("respdata", respData);
    return {
      data: respData
    }
  }
  catch (err) {
    return {
      error: err.response.data
    }
  }
}

export const getnewcustomercounthook = async (data) => {

  try {
    let respData = await axios({
      'method': 'GET',
      'url': API_URL + api.getnewcustomerApi,
      'headers': headers
    });
    console.log("respdata", respData);
    return {
      data: respData
    }
  }
  catch (err) {
    return {
      error: err.response.data
    }
  }
}

export const getnewguestusercounthook = async (data) => {

  try {
    let respData = await axios({
      'method': 'GET',
      'url': API_URL + api.getnewguestuserApi,
      'headers': headers
    });
    console.log("respdata", respData);
    return {
      data: respData
    }
  }
  catch (err) {
    return {
      error: err.response.data
    }
  }
}

export const getnewhostusercounthook = async (data) => {

  try {
    let respData = await axios({
      'method': 'GET',
      'url': API_URL + api.getnewhostuserApi,
      'headers': headers
    });
    console.log("respdata", respData);
    return {
      data: respData
    }
  }
  catch (err) {
    return {
      error: err.response.data
    }
  }
}
export const getallhostuserhook = async (data) => {

  try {
    let respData = await axios({
      'method': 'GET',
      'url': API_URL + api.getallhostuserApi,
      'headers': headers,
      params : data
    });
    console.log("respdata", respData);
    return {
      data: respData
    }
  }
  catch (err) {
    return {
      error: err.response.data
    }
  }
}





export const getallroomshook = async (data) => {

  try {
    let respData = await axios({
      'method': 'GET',
      'url': API_URL + api.getallroomsApi,
      'headers': headers,
      params : data
    });
    console.log("respdata", respData);
    return {
      data: respData
    }
  }
  catch (err) {
    return {
      error: err.response.data
    }
  }
}

export const deactivateroomhook = async (data) => {
  console.log("getfilter option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("id", data?.id);
      formdata.append("status", data?.status);
      // formdata.append("isapprove" , data.isapprove)
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.deactivateroomApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const activateroomhook = async (data) => {
  console.log("getfilter option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("id", data)
      // formdata.append("isapprove" , data.isapprove)
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.activateroomApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const acceptuserverificationhook = async (data) => {
  console.log("getfilter option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("id", data)
      // formdata.append("isapprove" , data.isapprove)
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.acceptuserverificationApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const rejectuserverificationhook = async (data) => {
  console.log("getfilter option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("id", data?._id);
      formdata.append("comments", data?.comments)
      // formdata.append("isapprove" , data.isapprove)
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.rejectuserverificationApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const onapprovedata = async (data) => {
  console.log("getfilter option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("id", data)
      // formdata.append("isapprove" , data.isapprove)
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.approveroomApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const onrejecteddata = async (data) => {
  console.log("getfilter option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("id", data)

    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.rejectroomApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const getallbookingshook = async (data) => {

  try {
    let respData = await axios({
      'method': 'GET',
      'url': API_URL + api.getallbookingApi,
      'headers': headers,
       params   : data
    });
    console.log("respdata", respData);
    return {
      data: respData
    }
  }
  catch (err) {
    return {
      error: err.response.data
    }
  }
}
export const getcancelledbookinghook = async (data) => {

  try {
    let respData = await axios({
      'method': 'GET',
      'url': API_URL + api.getcancelledbookingApi,
      'headers': headers
    });
    console.log("respdata", respData);
    return {
      data: respData
    }
  }
  catch (err) {
    return {
      error: err.response.data
    }
  }
}


export const addnewfloorplanhook = async (data) => {
  console.log("getfilter option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("type", data.type);
      formdata.append("status", data.status);
      formdata.append("count", data.count);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.addnewfloorplanApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const editfloorplanhook = async (data) => {
  console.log("getfilter option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("type", data.type);
      formdata.append("status", data.status);
      formdata.append("count", data.count);
      formdata.append("id", data.id);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.editfloorplanApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const deletefloorplanhook = async (data) => {
  console.log("getfilter option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("id", data);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.deletefloorplanApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}


export const getfloorplanhook = async (data) => {

  try {
    let respData = await axios({
      'method': 'GET',
      'url': API_URL + api.getallfloorplanApi,
      'headers': headers
    });
    console.log("respdata", respData);
    return {
      data: respData
    }
  }
  catch (err) {
    return {
      error: err.response.data
    }
  }
}

export const getpropertytypehook = async (data) => {

  try {
    let respData = await axios({
      'method': 'GET',
      'url': API_URL + api.getpropertytypeApi,
      'headers': headers
    });
    console.log("respdata", respData);
    return {
      data: respData
    }
  }
  catch (err) {
    return {
      error: err.response.data
    }
  }
}


export const addpropertytypehook = async (data) => {
  console.log("getfilter option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("heading", data.heading);
      formdata.append("status", data.status);
      formdata.append("content", data.content);
      formdata.append("image" , data?.image);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.addpropertytypeApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const editpropertytypehook = async (data) => {
  console.log("getfilter option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("heading", data.heading);
      formdata.append("status", data.status);
      formdata.append("content", data.content);
      formdata.append("id", data.id);
      formdata.append("image" , data?.image);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.editpropertytypeApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}


export const editlandcmshook = async (data) => {
  console.log("getfilter option data", data, data?.filter == "yes", data?.video);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("identifier", data.identifier);
      formdata.append("title", data.title);
      formdata.append("heading", data?.heading);
      formdata.append("content", data.content);// 3links
      formdata.append("content1", data.content1); // 3links
      formdata.append("content2", data.content2);// 3links
      formdata.append("content3", data.content3); //Footer
      formdata.append("image" , data?.image);
      formdata.append('contentarray', JSON.stringify(data?.contentarray))
      formdata.append("status", data?.status)
      if(data?.filter == "yes") {
        formdata.append("video" , data?.video);
        formdata.append("filter", data?.filter)
      } else {
        formdata.append("filter", data?.filter)
      }
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.editlandcmsApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }

    }

   
  }
  catch (e) {
    console.log("editlandcmshook_err", e);
  }
}

export const deletepropertytypehook = async (data) => {
  console.log("getfilter option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("id", data);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.deletepropertytypeApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const getprivacytypehook = async (data) => {

  try {
    let respData = await axios({
      'method': 'GET',
      'url': API_URL + api.getprivacytypeApi,
      'headers': headers
    });
    console.log("respdata", respData);
    return {
      data: respData
    }
  }
  catch (err) {
    return {
      error: err.response.data
    }
  }
}


export const getlegalhook = async (data) => {

  try {
    let respData = await axios({
      'method': 'GET',
      'url': API_URL + api.getlegalApi,
      'headers': headers
    });
    console.log("respdata", respData);
    return {
      data: respData
    }
  }
  catch (err) {
    return {
      error: err.response.data
    }
  }
}

export const addlegalandprivacy = async (data) => {
  console.log("getfilter option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("name", data.name);
      formdata.append("status", data.status);
      formdata.append("type", data.type);
      formdata.append("image", data.image)
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.addlegalprivacyApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const editlegalandprivacy = async (data) => {
  console.log("getfilter option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("image", data.image)
      formdata.append("name", data.name);
      formdata.append("status", data.status);
      formdata.append("type", data.type);
      formdata.append("id", data.id);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.editlegalprivacyApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const deletelegalandprivacy = async (data) => {
  console.log("getfilter option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("name", data.name);
      formdata.append("id", data.id);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.deletelegalprivacyApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const getammenitieshook = async (data) => {

  try {
    let respData = await axios({
      'method': 'GET',
      'url': API_URL + api.getamenitiesApi,
      'headers': headers
    });
    console.log("respdata", respData);
    return {
      data: respData
    }
  }
  catch (err) {
    return {
      error: err.response.data
    }
  }
}

export const getpropertytypegrouphook = async (data) => {

  try {
    let respData = await axios({
      'method': 'GET',
      'url': API_URL + api.getpropertytypegroupApi,
      'headers': headers
    });
    console.log("respdata", respData);
    return {
      data: respData
    }
  }
  catch (err) {
    return {
      error: err.response.data
    }
  }
}

export const getcategoryhook = async (data) => {

  try {
    let respData = await axios({
      'method': 'GET',
      'url': API_URL + api.getcategoryApi,
      'headers': headers
    });
    console.log("respdata", respData);
    return {
      data: respData
    }
  }
  catch (err) {
    return {
      error: err.response.data
    }
  }
}

export const getdescriptionhook = async (data) => {

  try {
    let respData = await axios({
      'method': 'GET',
      'url': API_URL + api.getdescriptionApi,
      'headers': headers
    });
    console.log("respdata", respData);
    return {
      data: respData
    }
  }
  catch (err) {
    return {
      error: err.response.data
    }
  }
}

export const addamenitytypehook = async (data) => {
  console.log("getfilter option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("name", data.name);
      formdata.append("type", data.type);
      formdata.append("image", data.image);
      formdata.append("status", data.status);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.addamenitytypeApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const editamenitytypehook = async (data) => {
  console.log("getfilter option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("name", data.name);
      formdata.append("type", data.type);
      formdata.append("image", data.image);
      formdata.append("status", data.status);
      formdata.append("id", data.id);
      formdata.append("description", data?.description);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.editamenitytypeApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const deleteamenitytypehook = async (data) => {
  console.log("getfilter option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("name", data.name);
      formdata.append("id", data.id);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.deleteamenitytypeApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const getcancellationpolicyhook = async (data) => {

  try {
    let respData = await axios({
      'method': 'GET',
      'url': API_URL + api.getcancellationpolicyApi,
      'headers': headers
    });
    console.log("respdata", respData);
    return {
      data: respData
    }
  }
  catch (err) {
    return {
      error: err.response.data
    }
  }
}

export const addcancellationpolicyhook = async (data) => {
  console.log("getfilter option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("days", data.days);
      formdata.append("type", data.type);
      formdata.append("percentage", data.percentage);
      formdata.append("status", data.status);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.addcancellationpolicyApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const editcancellationpolicyhook = async (data) => {
  console.log("getfilter option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("days", data.days);
      formdata.append("type", data.type);
      formdata.append("percentage", data.percentage);
      formdata.append("status", data.status);
      formdata.append("id", data?.id);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.editcancellationpolicyApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const deletecancellationpolicyhook = async (data, status) => {
  console.log("getfilter option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("id", data);
      formdata.append("status", status)
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.deletecancellationpolicyApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const getalldisputehook = async (data) => {

  try {
    let respData = await axios({
      'method': 'GET',
      'url': API_URL + api.getalldisputeApi,
      'headers': headers,
      params : data
    });
    console.log("respdata", respData);
    return {
      data: respData
    }
  }
  catch (err) {
    return {
      error: err.response.data
    }
  }
}

export const getallcrmhook = async (data) => {

  try {
    let respData = await axios({
      'method': 'GET',
      'url': API_URL + api.getallcrmApi,
      'headers': headers
    });
    console.log("respdata", respData);
    return {
      data: respData
    }
  }
  catch (err) {
    return {
      error: err.response.data
    }
  }
}

export const updatedisputehook = async (data) => {
  console.log("getfilter option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("ticketid", data?.ticketid);
      formdata.append("status", data?.status);
      formdata.append("statusreason", data?.statusreason);
      formdata.append("assignedto", data?.assignedto);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.updatedisputeApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

// export const acceptdisputebycrmhook = async(data) => {
//   console.log("getfilter option data" , data);
//   var formdata = new FormData();
//   try{
//     if(data){
//       formdata.append("tickedid" , data);
//       // formdata.append("status" , data?.status);
//     }
//     try {
//       let respdata = await axios({
//         'method': 'POST',
//         'url': API_URL + api.acceptdisputebycrmApi,
//         'credentials': true,
//         'headers': headers,
//         data: formdata,
//       });
//       console.log("response",respdata);
//       return {
//         data: respdata.data
//       }
//     }
//     catch (err) {
//       return {
//         error: err
//       }
//     }
//   }
//   catch(e){
//     console.log("error" , e);
//   }
// }

export const closeddisputemailhook = async (data) => {
  console.log("getfilter option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("tickedid", data?.ticketid);
      formdata.append("status", data?.status);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.closeddisputemailApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}



export const getallcouponhook = async (data) => {

  try {
    let respData = await axios({
      'method': 'GET',
      'url': API_URL + api.getallcouponApi,
      'headers': headers,
      params : data
    });
    console.log("respdata", respData);
    return {
      data: respData
    }
  }
  catch (err) {
    return {
      error: err.response.data
    }
  }
}

export const addcouponhook = async (data) => {
  console.log("getfilter option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("couponcode", data?.couponcode);
      formdata.append("discountamount", data?.discountamount);
      formdata.append("minamount", data?.minamount);
      formdata.append("currency", data?.currency);
      formdata.append("expireddate", data?.expiredate);
      formdata.append("status", data?.status);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.addcouponApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const editcouponhook = async (data) => {
  console.log("getfilter option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("id", data?.id);
      formdata.append("couponcode", data?.couponcode);
      formdata.append("discountamount", data?.discountamount);
      formdata.append("minamount", data?.minamount);
      formdata.append("currency", data?.currency);
      formdata.append("expireddate", data?.expiredate);
      formdata.append("status", data?.status);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.editcouponApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const deletecouponhook = async (data) => {
  console.log("getfilter option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("id", data);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.deletecouponApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const getsitesettinghook = async (data) => {

  try {
    let respData = await axios({
      'method': 'GET',
      'url': API_URL + api.getsitesettingApi,
      'headers': headers
    });
    console.log("respdata", respData);
    return {
      data: respData
    }
  }
  catch (err) {
    return {
      error: err.response.data
    }
  }
}

export const updatesitesettinghook = async (data) => {
  console.log("getfilter option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("sitename", data?.sitename);
      formdata.append("headcode", data?.headcode);
      formdata.append("logo", data?.logo);
      formdata.append("emaillogo", data?.emaillogo);
      formdata.append("favicon", data?.favicon);
      formdata.append("defaultcurrency", data?.defaultcurrency);
      formdata.append("paypalcurrency", data?.paypalcurrency);
      formdata.append("minimumprice", data?.minimumprice);
      formdata.append("maximumprice", data?.maximumprice);
      formdata.append("defaultlanguage", data?.defaultlanguage);
      formdata.append("fileuploaddriver", data?.fileuploaddriver);
      formdata.append("maintenancemode", data?.maintenancemode);
      formdata.append("helppagecoverimage", data?.helppagecoverimage);
      formdata.append("homepagestayimage", data?.homepagestayimage);
      formdata.append("homepageexperienceimage", data?.homepageexperienceimage);
      formdata.append("mobilebannerimage", data?.mobilebannerimage);
      formdata.append("mobilebannercontent", data?.mobilebannercontent);
      formdata.append("sitedateformat", data?.sitedateformat);
      formdata.append("version", data?.version);
      formdata.append("copyrightyear", data?.copyrightyear);
      formdata.append("copyrighturl", data?.copyrighturl);
      formdata.append("copyrightname", data?.copyrightname);
      formdata.append("adminprefix", data?.adminprefix);
      formdata.append("customersupportnumber", data?.customersupportnumber);
      formdata.append("facebooklogin", data?.facebooklogin);
      formdata.append("googlelogin", data?.googlelogin);
      formdata.append("applelogin", data?.applelogin);
      formdata.append("recaptcha", data?.recaptcha);
      formdata.append("covidverification", data?.covidverification);
      formdata.append("otp", data?.otp);

    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.updatesitesettingApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const getemailsettinghook = async (data) => {

  try {
    let respData = await axios({
      'method': 'GET',
      'url': API_URL + api.getemailsettingApi,
      'headers': headers
    });
    console.log("respdata", respData);
    return {
      data: respData
    }
  }
  catch (err) {
    return {
      error: err.response.data
    }
  }
}

export const updateemailsettinghook = async (data) => {
  console.log("getfilter option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("driver", data?.driver);
      formdata.append("host", data?.host);
      formdata.append("port", data?.port);
      formdata.append("fromaddress", data?.fromaddress);
      formdata.append("fromname", data?.fromname);
      formdata.append("encryption", data?.encryption);
      formdata.append("username", data?.username);
      formdata.append("password", data?.password);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.updateemailsettingApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const getjoinushook = async (data) => {

  try {
    let respData = await axios({
      'method': 'GET',
      'url': API_URL + api.getjoinusApi,
      'headers': headers
    });
    console.log("respdata", respData);
    return {
      data: respData
    }
  }
  catch (err) {
    return {
      error: err.response.data
    }
  }
}

export const updatejoinushook = async (data) => {
  console.log("getfilter option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("facebook", data?.facebook);
      formdata.append("twitter", data?.twitter);
      formdata.append("linkedin", data?.linkedin);
      formdata.append("pinterest", data?.pinterest);
      formdata.append("youtube", data?.youtube);
      formdata.append("instagram", data?.instagram);
      formdata.append("playstore", data?.playstore);
      formdata.append("appstore", data?.appstore);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.updatejoinusApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const getexperiencedetailhook = async (data) => {
  console.log("getfilter option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("name", data);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.getexperiencedetailApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const updateexperiencedetailhook = async (data) => {
  console.log("getfilter option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("name", data?.name);
      formdata.append("id", data?.id);
      formdata.append("status", data?.status);
      formdata.append("type", data?.type);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.updateexperiencedetailApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const addexperiencedetailhook = async (data) => {
  console.log("getfilter option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("name", data?.name);
      formdata.append("status", data?.status);
      formdata.append("type", data?.type);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.addexperiencedetailApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const deactivateexperiencedetailhook = async (data) => {
  console.log("getfilter option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("id", data?.id);
      formdata.append("name", data?.name);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.deactivateexperiencedetailApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}


export const gethostingexperiencehook = async (data) => {

  try {
    let respData = await axios({
      'method': 'GET',
      'url': API_URL + api.gethostingexperienceApi,
      'headers': headers,
      params : data
    });
    console.log("respdata", respData);
    return {
      data: respData
    }
  }
  catch (err) {
    return {
      error: err.response.data
    }
  }
}

export const approvehostingexperiencehook = async (data) => {
  console.log("approve option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("id", data);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.approvehostingexperienceApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const rejecthostingexperiencehook = async (data) => {
  console.log("getfilter option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("id", data);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.rejecthostingexperienceApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const deactivatehostingexperiencehook = async (data) => {
  console.log("getfilter option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("id", data);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.deactivatehostingexperienceApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}


export const reactivatehostingexperiencehook = async (data) => {
  console.log("getfilter option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("id", data);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.reactivatehostingexperienceApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}


export const disputechathook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("id", data?.id);
      formdata.append("sender", data?.sender);
      formdata.append("receiver", data?.receiver);
      formdata.append("message", data?.message);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.disputechatApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const getchathook = async (data) => {
  console.log("getfilter option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("id", data);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.getchatApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const getpaymentgatewayhook = async (data) => {

  try {
    let respData = await axios({
      'method': 'GET',
      'url': API_URL + api.getpaymentgatewayApi,
      'headers': headers
    });
    console.log("respdata", respData);
    return {
      data: respData
    }
  }
  catch (err) {
    return {
      error: err.response.data
    }
  }
}

export const updatepaymentgatewayhook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("braintreemode", data?.braintreemode);
      formdata.append("braintreemerchantid", data?.braintreemerchantid);
      formdata.append("braintreemerchantaccountid", data?.braintreemerchantaccountid);
      formdata.append("braintreepublickey", data?.braintreepublickey);
      formdata.append("braintreeprivatekey", data?.braintreeprivatekey);
      formdata.append("braintreetokenizationkey", data?.braintreetokenizationkey);
      formdata.append("paypalclientid", data?.paypalclientid);
      formdata.append("paypalsecret", data?.paypalsecret);
      formdata.append("stripepublishablekey", data?.stripepublishablekey);
      formdata.append("stripesecretkey", data?.stripesecretkey);
      formdata.append("stripeclientid", data?.stripeclientid);
      formdata.append("flutterwavepublishablekey", data?.flutterwavepublishablekey);
      formdata.append("flutterwavesecretkey", data?.flutterwavesecretkey);
      formdata.append("encryptionkey", data?.encryptionkey);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.updatepaymentgatewayApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const getallguestuserhook = async (data) => {

  try {
    let respData = await axios({
      'method': 'GET',
      'url': API_URL + api.getallguestuserApi,
      'headers': headers,
      params : data
    });
    console.log("respdata", respData);
    return {
      data: respData
    }
  }
  catch (err) {
    return {
      error: err.response.data
    }
  }
}

export const filterguestuserhook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("type", data?.type);
      formdata.append("startdate", data?.startdate);
      formdata.append("enddate", data?.enddate);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.filterguestuserApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const filterhostuserhook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("type", data?.type);
      formdata.append("startdate", data?.startdate);
      formdata.append("enddate", data?.enddate);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.filterhostuserApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const filterallbookinghook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("type", data?.type);
      formdata.append("startdate", data?.startdate);
      formdata.append("enddate", data?.enddate);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.filterallbookingApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const filterallroomshook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("type", data?.type);
      formdata.append("startdate", data?.startdate);
      formdata.append("enddate", data?.enddate);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.filterallroomsApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const filterallexperienceshook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("type", data?.type);
      formdata.append("startdate", data?.startdate);
      formdata.append("enddate", data?.enddate);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.filterallexperienceApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}
export const addfeehook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("name", data?.name);
      formdata.append("percentage", data?.percentage);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.addfeeApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const updatefeehook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("name", data?.name);
      formdata.append("percentage", data?.percentage);
      formdata.append("id", data?.id);
      formdata.append("status", data?.status);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.updatefeeApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const getallfeehook = async (data) => {

  try {
    let respData = await axios({
      'method': 'GET',
      'url': API_URL + api.getfeeApi,
      'headers': headers
    });
    console.log("respdata", respData);
    return {
      data: respData
    }
  }
  catch (err) {
    return {
      error: err.response.data
    }
  }
}

export const deactivatefeehook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("id", data);

    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.deactivatefeeApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const getapicredentialhook = async (data) => {

  try {
    let respData = await axios({
      'method': 'GET',
      'url': API_URL + api.getapicredentialApi,
      'headers': headers
    });
    console.log("respdata", respData);
    return {
      data: respData
    }
  }
  catch (err) {
    return {
      error: err.response.data
    }
  }
}

export const updateapicredentialhook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("facebookclientid", data?.facebookclientid);
      formdata.append("facebookclientsecret", data?.facebookclientsecret);
      formdata.append("googleclientid", data?.googleclientid);
      formdata.append("googleclientsecret", data?.googleclientsecret);
      formdata.append("googlemapbrowserkey", data?.googlemapbrowserkey);
      formdata.append("googlemapserverkey", data?.googlemapserverkey);
      formdata.append("cloudinaryname", data?.cloudinaryname);
      formdata.append("cloudinarykey", data?.cloudinarykey);
      formdata.append("cloudinarysecret", data?.cloudinarysecret);
      formdata.append("cloudinarybaseurl", data?.cloudinarybaseurl);
      formdata.append("cloudinarysecreturl", data?.cloudinarysecreturl);
      formdata.append("cloudinaryapiurl", data?.cloudinaryapiurl);
      formdata.append("appleteamid", data?.appleteamid);
      formdata.append("appleserviceid", data?.appleserviceid);
      formdata.append("applekeyid", data?.applekeyid);
      formdata.append("applekeyfile", data?.applekeyfile);
      formdata.append("fcmserverkey", data?.fcmserverkey);
      formdata.append("firebaseserviceaccountfile", data?.firebaseserviceaccountfile);
      formdata.append("firebasedatabaseurl", data?.firebasedatabaseurl);
      formdata.append("firebaseapikey", data?.firebaseapikey);
      formdata.append("firebaseauthdomain", data?.firebaseauthdomain);
      formdata.append("firebaseprojectid", data?.firebaseprojectid);
      formdata.append("firebasemessagingsenderid", data?.firebasemessagingsenderid);
      formdata.append("firebaseappid", data?.firebaseappid);
      formdata.append("recaptchasitekey", data?.recaptchasitekey);
      formdata.append("recaptchasecretkey", data?.recaptchasecretkey);

    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.updateapicredentialApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}






export const getsinglehostuserroomshook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data);

    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.getsinglehostuserroomsApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const getsinglehostuserroomcounthook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data);

    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.getsinglehostuserroomcountApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const getsinglehostuserlistedroomhook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data);

    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.getsinglehostuserlistedroomsApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const getsinglehostuserlistedroomcounthook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data);

    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.getsinglehostuserlistedroomcountApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const getsinglehostuserunlistedroomhook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data);

    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.getsinglehostuserunlistedroomApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const getsinglehostuserunlistedroomcounthook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data);

    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.getsinglehostuserunlistedroomcountApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const getsinglehostusertotalbookinghook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data);

    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.getsinglehostusertotalbookingApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const getsinglehostusertotalbookingcounthook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data);

    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.getsinglehostusertotalbookingcountApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const getsinglehostuseracccpetedbookinghook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data);

    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.getsinglehostuseracceptedbookingApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const getsinglehostuseracceptedbookingcounthook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data);

    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.getsinglehostuseracceptedbookingcountApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const getsinglehostuserrejectedbookinghook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data);

    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.getsinglehostuserrejectedbookingApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const getsinglehostuserrejectedbookingcounthook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data);

    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.getsinglehostuserrejectedbookingcountApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const getsinglehostusercancelledbookinghook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data);

    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.getsinglehostusercancelledbookingApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const getsinglehostusercancelledbookingcounthook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data);

    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.getsinglehostusercancelledbookingcountApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const getsinglehostuserexpiredbookinghook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data);

    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.getsinglehostuserexpiredbookingApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const getsinglehostuserexpiredbookingcounthook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data);

    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.getsinglehostuserexpiredbookingcountApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const getsinglehostuserpendingbookinghook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data);

    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.getsinglehostuserpendingbookingApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const getsinglehostuserpendingbookingcounthook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data);

    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.getsinglehostuserpendingbookingcountApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const getsinglehostuserpreapprovedbookinghook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data);

    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.getsinglehostuserpreapprovedbookingApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const getsinglehostuserpreapprovedbookingcounthook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data);

    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.getsinglehostuserpreapprovedbookingcountApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const filtersinglehostuserroomhook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data?.email);
      formdata.append("type", data?.type);
      formdata.append("startdate", data?.startdate);
      formdata.append("enddate", data?.enddate);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.filtersinglehostuserroomsApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const filtersinglehostuserlistedroomhook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data?.email);
      formdata.append("type", data?.type);
      formdata.append("startdate", data?.startdate);
      formdata.append("enddate", data?.enddate);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.filtersinglehostuserlistedroomsApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const filtersinglehostuserunlistedroomhook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data?.email);
      formdata.append("type", data?.type);
      formdata.append("startdate", data?.startdate);
      formdata.append("enddate", data?.enddate);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.filtersinglehostuserunlistedroomsApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const filtersinglehostuserbookinghook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data?.email);
      formdata.append("type", data?.type);
      formdata.append("startdate", data?.startdate);
      formdata.append("enddate", data?.enddate);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.filtersinglehostuserbookingApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const filtersinglehostuseracceptedbookinghook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data?.email);
      formdata.append("type", data?.type);
      formdata.append("startdate", data?.startdate);
      formdata.append("enddate", data?.enddate);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.filtersinglehostuseracceptedbookingApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const filtersinglehostuserrejectedbookinghook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data?.email);
      formdata.append("type", data?.type);
      formdata.append("startdate", data?.startdate);
      formdata.append("enddate", data?.enddate);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.filtersinglehostuserrejectedbookingApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const filtersinglehostusercancelledbookinghook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data?.email);
      formdata.append("type", data?.type);
      formdata.append("startdate", data?.startdate);
      formdata.append("enddate", data?.enddate);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.filtersinglehostusercancelledbookingApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const filtersinglehostuserexpiredbookinghook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data?.email);
      formdata.append("type", data?.type);
      formdata.append("startdate", data?.startdate);
      formdata.append("enddate", data?.enddate);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.filtersinglehostuserexpiredbookingApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const filtersinglehostuserpreapprovedbookinghook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data?.email);
      formdata.append("type", data?.type);
      formdata.append("startdate", data?.startdate);
      formdata.append("enddate", data?.enddate);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.filtersinglehostuserpreapprovedbookingApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const filtersinglehostuserpendingbookinghook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data?.email);
      formdata.append("type", data?.type);
      formdata.append("startdate", data?.startdate);
      formdata.append("enddate", data?.enddate);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.filtersinglehostuserpendingbookingApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const getadminearninghook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.getadminearningApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const gethostearninghook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.getsinglehostearningApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const filteradminearninghook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data?.email);
      formdata.append("type", data?.type);
      formdata.append("startdate", data?.startdate);
      formdata.append("enddate", data?.enddate);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.filteradminearningApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const filtersinglehostearninghook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data?.email);
      formdata.append("type", data?.type);
      formdata.append("startdate", data?.startdate);
      formdata.append("enddate", data?.enddate);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.filtersinglehostearningApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const sendemailhook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("to", data?.email);
      formdata.append("subject", data?.subject);
      formdata.append("description", data?.description);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.sendmailApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const forgotpasswordmailhook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data);

    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.forgotpasswordmailApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const forgotpasswordhook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data?.email);
      formdata.append("password", data?.password)
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.forgotpasswordApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const getsinglecrmdisputehook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("name", data);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.getsinglecrmdisputeApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const acceptthedisputebycrmhook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("ticketid", data);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.acceptthedisputebycrmApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}


export const getallcmshook = async (data) => {

  try {
    let respData = await axios({
      'method': 'GET',
      'url': API_URL + api.getcmsApi,
      'headers': headers
    });
    console.log("respdata", respData);
    return {
      data: respData
    }
  }
  catch (err) {
    return {
      error: err.response.data
    }
  }
}

export const getalllandcmsdatahook = async (data) => {

  try {
    let respData = await axios({
      'method': 'POST',
      'url': API_URL + api.getlandcmsApi,
      'headers': headers
    });
    console.log("respdata", respData);
    return {
      data: respData
    }
  }
  catch (err) {
    return {
      error: err.response.data
    }
  }
}




export const  editcmshook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      // formdata.append("image", data?.image);
      formdata.append("id", data?.id);
      formdata.append("name", data?.name);
      formdata.append("image", data?.image);
      formdata.append("content", data?.content);
      formdata.append("status", data?.status);
      // if(data?.multipleimages?.length > 0){
      //   for (let i=0; i < data?.multipleimages?.length; i++){
      //     {console.log("multipleimages looping", data?.multipleimages[i])}
      //     formdata.append( "multipleimages"+i,data?.multipleimages[i])
      //   }
      // }
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.editcmsApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const deactivatecmshook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("id", data?.id);
      formdata.append("status", data?.status);

    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.deactivatecmsApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}


export const deactivateaboutcmshook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("id", data?.id);
      formdata.append("status", data?.status);

    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.deactivateaboutcmsApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("deactivateaboutcmshook_err", e);
  }
}

export const addcmshook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("name", data?.name);
      formdata.append("image", data?.image);
      formdata.append("content", data?.content);
      formdata.append("status", data?.status);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.addcmsApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const getwithdrawhook = async (data) => {

  try {
    let respData = await axios({
      'method': 'GET',
      'url': API_URL + api.getwithdrawApi,
      'headers': headers
    });
    console.log("respdata", respData);
    return {
      data: respData
    }
  }
  catch (err) {
    return {
      error: err.response.data
    }
  }
}

//payment integration

export const createwithdrawpaymenthook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("id", data?.id);
      formdata.append("amount", data?.amount);
      data?.type && formdata.append("type", data?.type)
      formdata.append("paymenttype" , data?.paymenttype);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.createwithdrawpaymentApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const verifywithdrawpaymenthook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("id", data?.id);
      formdata.append("paymentverified", JSON.stringify(data?.paymentverified));
      data?.type && formdata.append("type", data?.type);
      // formdata.append("razorpay_payment_id" , data?.paymentid);
      // formdata.append("razorpay_signature" , data?.signature);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.verifywithdrawpaymentApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const manualwithdrawpaymenthook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("image", data?.image);
      formdata.append("id", data?.id);
      formdata.append("type", data?.type);
      formdata.append("requestamount" , data?.amount);
      formdata.append("hostemail" , data?.hostemail);
      // formdata.append("razorpay_payment_id" , data?.paymentid);
      // formdata.append("razorpay_signature" , data?.signature);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.manualwithdrawpaymentApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

//experience finance report

export const getsinglehosttotalexperiencehook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data);
      // formdata.append("image" , data?.image);
      // formdata.append("content" , data?.content);
      // formdata.append("status" , data?.status);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.getsinglehosttotalexperienceApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const getsinglehosttotalexperiencecounthook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data);
      // formdata.append("image" , data?.image);
      // formdata.append("content" , data?.content);
      // formdata.append("status" , data?.status);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.getsinglehosttotalexperiencecountApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const filtersinglehosttotalexperiencehook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data?.email);
      formdata.append("type", data?.type);
      formdata.append("startdate", data?.startdate);
      formdata.append("enddate", data?.enddate);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.filtersinglehosttotalexperienceApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const getsinglehostapprovedexperiencehook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data);
      // formdata.append("type" , data?.type);
      // formdata.append("startdate" , data?.startdate);
      // formdata.append("enddate" , data?.enddate);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.getsinglehostapprovedexperienceApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const getsinglehostapprovedexperiencecounthook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data);
      // formdata.append("type" , data?.type);
      // formdata.append("startdate" , data?.startdate);
      // formdata.append("enddate" , data?.enddate);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.getsinglehostapprovedexperiencecountApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const filtersinglehostapprovedexperiencehook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data?.email);
      formdata.append("type", data?.type);
      formdata.append("startdate", data?.startdate);
      formdata.append("enddate", data?.enddate);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.filtersinglehostapprovedexperienceApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const getsinglehostpendingexperiencehook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data);
      // formdata.append("type" , data?.type);
      // formdata.append("startdate" , data?.startdate);
      // formdata.append("enddate" , data?.enddate);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.getsinglehostpendingexperienceApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const getsinglehostpendingexperiencecounthook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data);
      // formdata.append("type" , data?.type);
      // formdata.append("startdate" , data?.startdate);
      // formdata.append("enddate" , data?.enddate);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.getsinglehostpendingexperiencecountApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const filtersinglehostpendingexperiencehook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data?.email);
      formdata.append("type", data?.type);
      formdata.append("startdate", data?.startdate);
      formdata.append("enddate", data?.enddate);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.filtersinglehostpendingexperienceApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const getsinglehostrejectedexperiencehook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data);
      // formdata.append("type" , data?.type);
      // formdata.append("startdate" , data?.startdate);
      // formdata.append("enddate" , data?.enddate);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.getsinglehostrejectedexperienceApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const getsinglehostrejectedexperiencecounthook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data);
      // formdata.append("type" , data?.type);
      // formdata.append("startdate" , data?.startdate);
      // formdata.append("enddate" , data?.enddate);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.getsinglehostrejectedexperiencecountApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const filtersinglehostrejectedexperiencehook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data?.email);
      formdata.append("type", data?.type);
      formdata.append("startdate", data?.startdate);
      formdata.append("enddate", data?.enddate);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.filtersinglehostrejectedexperienceApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const getsinglehosttotalexperiencebookinghook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data);
      // formdata.append("type" , data?.type);
      // formdata.append("startdate" , data?.startdate);
      // formdata.append("enddate" , data?.enddate);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.getsinglehosttotalexperiencebookingApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const getsinglehosttotalexperiencebookingcounthook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data);
      // formdata.append("type" , data?.type);
      // formdata.append("startdate" , data?.startdate);
      // formdata.append("enddate" , data?.enddate);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.getsinglehosttotalexperiencebookingcountApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const filtersinglehosttotalexperiencebookinghook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data?.email);
      formdata.append("type", data?.type);
      formdata.append("startdate", data?.startdate);
      formdata.append("enddate", data?.enddate);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.filtersinglehosttotalexperiencebookingApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const getsinglehostcancelledexperiencehook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data);
      // formdata.append("type" , data?.type);
      // formdata.append("startdate" , data?.startdate);
      // formdata.append("enddate" , data?.enddate);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.getsinglehostcancelledexperienceApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const getsinglehostcancelledexperiencecounthook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data);
      // formdata.append("type" , data?.type);
      // formdata.append("startdate" , data?.startdate);
      // formdata.append("enddate" , data?.enddate);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.getsinglehostcancelledexperiencecountApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const filtersinglehostcancelledexperiencehook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data?.email);
      formdata.append("type", data?.type);
      formdata.append("startdate", data?.startdate);
      formdata.append("enddate", data?.enddate);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.filtersinglehostcancelledexperienceApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const getsinglehostexperienceadminearninghook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data);
      // formdata.append("type" , data?.type);
      // formdata.append("startdate" , data?.startdate);
      // formdata.append("enddate" , data?.enddate);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.getsinglehostexperienceadminearningApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const filtersinglehostexperienceadminearninghook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data?.email);
      formdata.append("type", data?.type);
      formdata.append("startdate", data?.startdate);
      formdata.append("enddate", data?.enddate);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.filtersinglehostexperienceadminearningApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const getsinglehostexperienceearninghook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data);
      // formdata.append("type" , data?.type);
      // formdata.append("startdate" , data?.startdate);
      // formdata.append("enddate" , data?.enddate);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.getsinglehostexperienceearningApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const filtersinglehostexperienceearninghook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data?.email);
      formdata.append("type", data?.type);
      formdata.append("startdate", data?.startdate);
      formdata.append("enddate", data?.enddate);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.fitersinglehostexperienceearningApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const getallexperiencebookinghook = async (data) => {

  try {
    let respData = await axios({
      'method': 'GET',
      'url': API_URL + api.getallexperiencebookingApi,
      'headers': headers,
      params : data
    });
    console.log("respdata", respData);
    return {
      data: respData
    }
  }
  catch (err) {
    return {
      error: err.response.data
    }
  }
}

export const getallcancelbookingroomhook = async (data) => {

  try {
    let respData = await axios({
      'method': 'GET',
      'url': API_URL + api.getallcancelbookingroomApi,
      'headers': headers,
      params : data
    });
    console.log("respdata", respData);
    return {
      data: respData
    }
  }
  catch (err) {
    return {
      error: err.response.data
    }
  }
}


export const CsvfloorplanfileUpload = async (reqData) => {
  console.log('resssssssssss', reqData.content[0])
  let data = {
    '_id': reqData._id,
    // 'content' : JSON.stringify(reqdata.content)
    'content': reqData.content
  }
  console.log('dftadfasdfasdfas', data)
  try {
    let respData = await axios({
      'method': 'POST',
      'url': API_URL + api.csvfloorfileuploadApi,
      'headers': headers,
      data: data
    });
    return {
      data: respData
    }
  }
  catch (err) {

  }
}


export const CsvPropertyTypefileUpload = async (reqData) => {
  console.log('ressssssssssssss', reqData.content[0]);
  let data = {
    '_id': reqData._id,
    'content': reqData.content
  }

  try {
    let respData = await axios({
      'method': 'POST',
      'url': API_URL + api.csvpropertyfileuploadApi,
      'headers': headers,
      data: data
    });
    return {
      data: respData
    }
  }
  catch (err) {

  }
}

export const CsvdescriptionfileUpload = async (reqData) =>
{
  let data = {
    '_id': reqData._id,
    'content': reqData.content
  }

  try {
    let respData = await axios({
      'method': 'POST',
      'url': API_URL + api.csvdescriptionfileuploadApi,
      'headers': headers,
      data: data
    });
    return {
      data: respData
    }
  }
  catch (err) {

  }

}

export const CsvlegalfileUpload = async (reqData) =>
{
  let data = {
    '_id': reqData._id,
    'content': reqData.content
  }

  try {
    let respData = await axios({
      'method': 'POST',
      'url': API_URL + api.csvlegalfileuploadApi,
      'headers': headers,
      data: data
    });
    return {
      data: respData
    }
  }
  catch (err) {

  }

}


export const csvamenitiesfileUpload = async (reqData) =>
{
  let data = {
    '_id': reqData._id,
    'content': reqData.content
  }

  try {
    let respData = await axios({
      'method': 'POST',
      'url': API_URL + api.csvamenitiesfileuploadApi,
      'headers': headers,
      data: data
    });
    return {
      data: respData
    }
  }
  catch (err) {

  }
}


export const csvcategoryfileUpload = async (reqData) =>
{
  let data = {
    '_id' : reqData._id,
    'content' : reqData.content
  }

  try {
    let respData = await axios({
      'method': 'POST',
      'url': API_URL + api.csvcategoryfileuploadApi,
      'headers': headers,
      data: data
    });
    return {
      data: respData
    }
  }
  catch (err) {

  }
}


export const getallemailtemplatehook = async (data) => {

  try{
    let respData = await axios({
      'method': 'GET',
      'url': API_URL + api.getallemailtemplateApi,
      headers: headers,
      params : data

    });
    console.log("getallemailtemplatehook", respData)
    return {
      data: respData
    }
  } catch (e) {
    return {
      error: e.response.data
    }
  }
}

export const deactivateemailtemplatehook = async (data) => {
  console.log("add content option data", data, data?._id);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("id", data);
      // formdata.append("satus" , data.status)
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.deactivateemailtemplateApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const editemailtemplatehook = async (data) => {
  var formdata = new FormData();
  try{
    formdata.append("id", data.id);
    formdata.append("status", data.status);
    formdata.append("subject", data.subject);
    formdata.append("content", data.content);

    let respdata = await axios({
      'method': 'POST',
      'url': API_URL + api.editemailtemplateApi,
      'credentials': true,
      'headers': headers,
      data: formdata,
    });
    console.log("response", respdata);
      return {
        data: respdata.data
      }
  } catch (e) {
    console.log("editemailtemplatehook_err", e)
  }
}



export const Addsubscriptionhooks = async (data) => {
  var formdata = new FormData();
  try{
    formdata.append("name", data.name);
    formdata.append("price", data.price);
    formdata.append("days", data.days);
    formdata.append("type", data.type);
    formdata.append("percentage" , data?.percentage);
    let respdata = await axios({
      'method': 'POST',
      'url': API_URL + api.addsubscriptionApi,
      'credentials': true,
      'headers': headers,
      data: formdata,
    });
    console.log("response", respdata);
      return {
        data: respdata.data
      }
  } catch (e) {
    console.log("editemailtemplatehook_err", e)
  }
}

export const Editsubscriptionhooks = async (data) => {
  var formdata = new FormData();
  try{
    formdata.append("name", data?.name);
    formdata.append("price", data?.price);
    formdata.append("days", data?.days);
    formdata.append("status", data?.status);
    formdata.append("id", data?.id);
    formdata.append("type", data.type);
    formdata.append("percentage" , data?.percentage);

    if(data?.image) {
      formdata?.append("image", data?.image)
    }
    console.log('get subsc', data?.image, data)
    let respdata = await axios({
      'method': 'POST',
      'url': API_URL + api.editsubscriptionApi,
      'credentials': true,
      'headers': headers,
      data: formdata,
    });
    console.log("response", respdata);
      return {
        data: respdata.data
      }
  } catch (e) {
    console.log("editemailtemplatehook_err", e)
  }
}

export const getsubscriptionhooks = async (data) => {

  try{
    let respData = await axios({
      'method': 'GET',
      'url': API_URL + api.getsubscriptionApi,
      headers: headers,
      params : data

    });
    console.log("getallemailtemplatehook", respData)
    return {
      data: respData
    }
  } catch (e) {
    return {
      error: e.response.data
    }
  }
}

export const getstaydatehooks = async (data) => {

  try{
    let respData = await axios({
      'method': 'GET',
      'url': API_URL + api.getstaydateApi,
      headers: headers,
      params : data

    });
    console.log("getallemailtemplatehook", respData)
    return {
      data: respData
    }
  } catch (e) {
    return {
      error: e.response.data
    }
  }
}

export const updatestaydatehooks = async (data) => {
  var formdata = new FormData();
  try{
    formdata.append("min", data?.min);
    formdata.append("max", data?.max);
    // formdata.append("days", data?.days);
    // formdata.append("status", data?.status);
    formdata.append("id", data?.id);

    let respdata = await axios({
      'method': 'POST',
      'url': API_URL + api.updatestaydateApi,
      'credentials': true,
      'headers': headers,
      data: formdata,
    });
    console.log("response", respdata);
      return {
        data: respdata.data
      }
  } catch (e) {
    console.log("editemailtemplatehook_err", e)
  }
}


export const getallfaqcmshooks = async (data) => {

  try {
    let respData = await axios({
      'method': 'GET',
      'url': API_URL + api.getfaqcmsApi,
      'headers': headers
    });
    console.log("respdata", respData);
    return {
      data: respData
    }
  }
  catch (err) {
    return {
      error: err.response.data
    }
  }
}


export const addfaqhooks = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("question", data?.question);
      formdata.append("answer", data?.answer);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.addfaqApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const deactivatesubscriptionhooks = async (data) => {
  try {
    console.log("dispute chat option data", data);
    var formdata = new FormData();
    if (data) {
      formdata.append("id", data?.id);
      formdata.append("status", data?.status)
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.deactivatesubscription,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });

      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
  } catch (e) {
    console.log("deactivatesubscriptionhooks_err", e)
  }
}


export const  editfaqhook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
     
      formdata.append("question", data?.question);
      formdata.append("answer", data?.answer)
      formdata.append("id", data?.id)

    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.editfaqApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}


export const  admininstantbookinghooks = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("instantbooking", data?.instantbooking);
      formdata.append("admininstantbooking", data?.admininstantbooking);
      formdata.append("id", data?.id)
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.admininstantbookingApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}


export const DateTimeForm = (date,datealone,timealone,ampm) => {
  try{
    if(datealone){
      return `${MinuteHourFormat(new Date(date)?.getDate())}/${MinuteHourFormat(new Date(date)?.getMonth()+1)}/${MinuteHourFormat(new Date(date)?.getFullYear())}`
    }
    else if(timealone){
      if(ampm){
        return `${MinuteHourFormat(new Date(date)?.getHours() > 12 ? new Date(date)?.getHours() - 12 : new Date(date)?.getHours()) }:${MinuteHourFormat(new Date(date)?.getMinutes())} ${new Date(date)?.getHours() >= 12 ? 'PM' : 'AM' }`
      }
      else{
        return `${MinuteHourFormat(new Date(date)?.getHours())}:${MinuteHourFormat(new Date(date)?.getMinutes())} `
      }
    }
    else if(ampm){
      return `${MinuteHourFormat(new Date(date)?.getDate())}/${MinuteHourFormat(new Date(date)?.getMonth()+1)}/${MinuteHourFormat(new Date(date)?.getFullYear())}, ${MinuteHourFormat(new Date(date)?.getHours() > 12 ? new Date(date)?.getHours() - 12 : new Date(date)?.getHours())}:${MinuteHourFormat(new Date(date)?.getMinutes()) } ${new Date(date)?.getHours() >= 12 ? 'PM' : 'AM'} `
    }
    return `${MinuteHourFormat(new Date(date)?.getDate())}:${MinuteHourFormat(new Date(date)?.getMonth()+1)}:${new Date(date)?.getFullYear()},${MinuteHourFormat(new Date(date)?.getHours())}:${MinuteHourFormat(new Date(date)?.getMinutes())} `
  }
  catch(err){
    console.log('dskhfskfs',err)
    return "No Date"
  }
}


const MinuteHourFormat = (data) => {
  return ((Number(isEmpty(data) ? 0 : data ) < 10 ? '0' : '')+data)
}

const isEmpty = value =>
value === undefined ||
value === null ||
(typeof value === 'object' && Object.keys(value).length === 0) ||
(typeof value === 'string' && value.trim().length === 0);


export const getsplitdayshooks = async (data) => {
  try {
    let respData = await axios({
      'method': 'GET',
      'url': API_URL + api.getsplitdaysApi,
      'headers': headers
    });
    console.log("respdata", respData);
    return {
      data: respData
    }
  }
  catch (err) {
    return {
      error: err.response.data
    }
  }
}

export const  editsplitdayshook = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("days", data);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.editsplitdaysApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}


export const  changebankdetailstatusHooks = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("email", data?.email);
      formdata.append("status", data?.status);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.changebankdetailstatusApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const getcontactus = async (data) => {
  try {
    let respData = await axios({
      'method': 'GET',
      'url': API_URL + api.getcontactusApi,
      'headers': headers
    });
    console.log("respdata", respData);
    return {
      data: respData
    }
  }
  catch (err) {
    return {
      error: err.response.data
    }
  }
}

export const getaboutcmshooks = async (data) => {
  try {
    let respData = await axios({
      'method': 'GET',
      'url': API_URL + api.getaboutcmsApi,
      'headers': headers
    });
    console.log("respdata", respData);
    return {
      data: respData
    }
  }
  catch (err) {
    return {
      error: err.response.data
    }
  }
}

export const  editaboutcmsHooks = async (data) => {
  console.log("dispute chat option data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("title", data?.title);
      formdata.append("heading", data?.heading);
      formdata.append("content", data?.content);
      formdata.append("content1", data?.content1);
      formdata.append("content2", data?.content2);
      formdata.append("content3", data?.content3);
      formdata.append("status", data?.status);
      formdata.append("identifier", data?.identifier);
      formdata.append("image", data?.image);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.editaboutcmsApi,
        'credentials': true,
        'headers': headers,
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}