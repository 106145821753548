import React, { useEffect, useState } from 'react';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';
import { FloatingLabel, Form, Button } from 'react-bootstrap'
import DataTable from 'react-data-table-component';
import { Bar } from "react-chartjs-2";
import Datepicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useHistory } from 'react-router-dom';
import { editlandcmshook, editpropertytypehook } from '../../../Hooks/adminHook';
import { API_URL } from '../../../config/env';
import { toast, Toaster } from "react-hot-toast"
import { toastOptions } from '../../../config/env';

const FormView = (props) => {

    const location = useLocation();
    // const navigate = useNavigate();
    const { push } = useHistory();
    const [propertytype, setPropertytype] = useState({});
    const [status, setStatus] = useState('');
    const [heading, setHeading] = useState('');
    const [content, setContent] = useState('');
    const [title, setTitle] = useState('');
    const [imageview, setImageview] = useState("");
    const [image, setImage] = useState("");
    const [identifier, setIdentifier] = useState('')
    const [contentarray, setContentArray] = useState([])
    const [videoname, setVideoname] = useState("");
    const [video, setVideo] = useState({});
    const [content1, setContent1] = useState('');
    const [content2, setContent2] = useState('');
    const [content3, setContent3] = useState('')



    // useEffect(() => {
    //     document.getElementById("managepropertytype").classList.add("active");
    //     if (window.innerWidth > 767) {
    //         // console.log(document.querySelectorAll(".list-group-item-action.active")[0].offsetTop,"top");
    //         if (document.querySelectorAll(".themescroll li a.active").length > 0) {
    //             var pagelinkofset = document.querySelectorAll(".themescroll li a.active")[0].offsetTop;
    //             console.log(pagelinkofset, "pagelinkofset")
    //             document.getElementById("scrollbar_sidebar_id").scrollTo(0, pagelinkofset - 200);

    //         }

    //     }
    //     else {
    //         // alert(2)
    //         if (document.querySelectorAll(".themescroll li a.active").length > 0) {
    //             // console.log(document.querySelectorAll(".list-group-item-action.active")[0].offsetTop,"top");
    //             if (document.querySelectorAll(".themescroll li a.active").length > 0) {
    //                 var pagelinkofset = document.querySelectorAll(".themescroll li a.active")[0].offsetTop;
    //                 document.getElementById("scrollbar_sidebar_id").scrollTo(0, pagelinkofset - 200);
    //             }
    //         }

    //     }
    // }, []);


    useEffect(() => {
        console.log("location data", location?.state);
        const { status, Image, Content, Identifier, Title, ContentArray, Content1, Content2, Content3, Video, Heading } = location?.state?.state;
        setStatus(status);
        setTitle(Title != "undefined" ? Title : "");
        setContent(Content != "undefined" ? Content : "");
        setContent1(Content1 != "undefined" ? Content1 : "");
        setContent2(Content2 != "undefined" ? Content2 : "");
        setContent3(Content3 != "undefined" ? Content3 : "")
        setImage(Image != "undefined" ? Image : "");
        setVideoname(Video != "undefined" ? Video : "")
        setIdentifier(Identifier != "undefined" ? Identifier : "")
        setContentArray(ContentArray)
        setHeading(Heading != "undefined" ? Heading : "")
    }, []);




    const handlesubmit = async () => {
        let payload = {}
        console.log('videoname != ""', videoname != "")
        payload = {
            identifier: identifier,
            title: title,
            content: content,
            content1 : content1,
            content2 : content2,
            content3 : content3,
            heading : heading,
            image: image,
            contentarray: contentarray,
            video: video,
            status: "active"
        }

        if (videoname != "") {
            payload.filter = "yes"
        } else {
            payload.filter = "no"
        }
        console.log('payload data', payload)
        let res = await editlandcmshook(payload);
        console.log('sdfasdfasdfasdfasdf', res)
        if (res?.data?.type == "success") {
            push("/manage-landingcms");
        }
        console.log("res datass", res)
    }

    const handlecontent = async (e, id, ind) => {
        console.log('eeeeeeeeeee', contentarray, e, id, ind)
        let arr = []
        contentarray && contentarray?.length > 0 && contentarray?.map((val, index) => {
            if (index == ind) {
                console.log('asdfasdfasdfasdf', val);
                arr.push({ ...val, ...{ [id]: e } })
            } else {
                arr.push(val)
            }
        });
        setContentArray(arr)
        console.log('asdfasdfasdf', arr)

    }


    const handleAddcontent = async (filt, ind) => {
        console.log('index will come', ind, filt)
        if (filt == "add") {
            console.log('conterasdfasdfasd', contentarray);
            let obj = {
                Content: "",
                Heading: "",
                Title: ""
            }
            console.log('asdfasdfasdfasdf', ...contentarray, 'ASDFASDF', obj, [...contentarray, obj])
            setContentArray([...contentarray, obj])
        } else {
            let arr = [];
            console.log('contentarray', contentarray)
            contentarray && contentarray?.length > 0 && contentarray?.map((val, i) => {
                console.log('index will come', ind, i, i != ind)
                if (i != ind) {
                    console.log('enter val', val, i, ind, i != ind)
                    arr.push(val)
                }
            })
            console.log('enter thuis one', arr)
            setContentArray(arr)
        }

    }



    return (
        <div id="dashboard" className='showsidebar'>
            <div id='sidebar'>
                <Sidebar />
            </div>
            <div className='headandcont'>
                <div className="adminheader"><AdminHeader /></div>
                <div className='cont'>
                    <h4 className='adminblue my-4'>Edit Landing Cms</h4>
                    <div className='admin-forms mb-4 '>
                        <div className='shadowbox p-4 br-10'>
                            <div className='row'>
                                {<div className='col-sm-6 mb-4'>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Identifier *"
                                        className="mb-3" >
                                        <Form.Control placeholder='demo' type="text" defaultValue={identifier} readOnly={true} />
                                    </FloatingLabel>
                                </div>}
                                {(identifier == "land_banner01" || identifier == "land_banner02" || identifier == "land_banner03" || identifier == "land_banner05" || identifier == "land_banner06" || identifier == "land_banner07") && <div className='col-sm-6 mb-4'>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Title *">
                                        <Form.Control placeholder='demo' type="text" readOnly={identifier == "land_banner06" ? true : false}defaultValue={title} onChange={(e) => setTitle(e?.target?.value)} />
                                    </FloatingLabel></div>}
                            </div>

                            <div className='row' >
                                {(identifier == "land_banner01" || identifier == "land_banner02" || identifier == "land_banner03" || identifier == "land_banner05" || identifier == "land_banner07") && <div className='col-sm-6 mb-4'>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Content *">
                                        <Form.Control placeholder='demo' type="text" value={content} onChange={(e) => setContent(e?.target?.value)} />
                                    </FloatingLabel></div>}
                                    

                                    {(identifier == "land_banner06") && <div className='col-sm-6 mb-4'>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Heading *">
                                        <Form.Control placeholder='demo' type="text" value={heading} onChange={(e) => setHeading(e?.target?.value)} />
                                    </FloatingLabel>
                                    
                                    
                                    </div>}
                                    
                                    {/* {(identifier == "land_banner06") && <div className='col-sm-6 mb-4'>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Instagram Link *">
                                        <Form.Control placeholder='demo' type="text" value={content} onChange={(e) => setContent(e?.target?.value)} />
                                    </FloatingLabel>
                                    
                                    
                                    </div>} */}
                                    
                                    {/* {(identifier == "land_banner06") && <div className='col-sm-6 mb-4'>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Facebook Link *">
                                        <Form.Control placeholder='demo' type="text" value={content1} onChange={(e) => setContent1(e?.target?.value)} />
                                    </FloatingLabel>
                                    
                                    
                                    </div>} */}
                                    {/* {(identifier == "land_banner06") && <div className='col-sm-6 mb-4'>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Twitter Link *">
                                        <Form.Control placeholder='demo' type="text" value={content2} onChange={(e) => setContent2(e?.target?.value)} />
                                    </FloatingLabel>
                                    
                                    
                                    </div>} */}

                                    {(identifier == "land_banner06") && <div className='col-sm-6 mb-4'>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Footer *">
                                        <Form.Control placeholder='demo' type="text" value={content3} onChange={(e) => setContent3(e?.target?.value)} />
                                    </FloatingLabel>
                                    
                                    
                                    </div>}

    {/* <Form.Control placeholder='demo' type="date" placeholder="name@example.com" className='' /> */}
                            </div>
                            {(identifier == "land_banner01") && <div className='row'>
                                <div className='col-sm-6 mb-4'>
                                    <h6>Image *:</h6>
                                    <div className='upphoto my-3 ml-6'>
                                        <Button variant="link" className='nounder text-dark me-2 mb-2'>
                                            <div className={imageview && 'd-none'}></div>
                                            <span className='fa fa-photo'></span>

                                            <p>Upload Image</p>
                                            {console.log("imageview ? imageview : API_URL +location?.state?.state?.path + image", imageview ? imageview : `${API_URL}${location?.state?.path}/${image}`)}
                                            <input type="file" name="img" accept=".jpg,.jpeg,.png" onChange={(e) => { setImage(e?.target?.files[0]); setImageview(URL.createObjectURL(e?.target?.files[0])) }} />
                                            <img src={imageview ? imageview : `${API_URL}adminImages/cmsimages/${image}`} />
                                        </Button>
                                    </div>
                                </div>
                            </div>}

                            {(identifier == "land_banner04") && <div className='row'>
                                <div className="upphoto my-3 upphoto_big">
                                    <Button variant="link" className="nounder text-dark me-2 mb-2">
                                        <span className="fa fa-video-camera" />

                                        <p>{videoname ? videoname : "Upload video"}</p>

                                        <input
                                            type="file"
                                            onChange={(e) => {
                                                var image = e?.target?.files[0];
                                                var fileName = image.name;
                                                var idxDot = fileName.lastIndexOf(".") + 1;
                                                var extFile = fileName
                                                    .substr(idxDot, fileName.length)
                                                    .toLowerCase();
                                                if (
                                                    extFile == "mp4" ||
                                                    extFile == "mov" ||
                                                    extFile == "m4v" ||
                                                    extFile == "mkv" ||
                                                    extFile == "avi"
                                                ) {
                                                    setVideoname(e?.target?.files[0]?.name);
                                                    setVideo(e?.target?.files[0]);
                                                } else {
                                                    toast.error("Invalid Video Format!..");
                                                }
                                            }}
                                        />
                                        {console.log('${API_URL}adminImages/cmsimages/${videoname}', `${API_URL}adminImages/cmsvideo/${videoname}`)}
                                         <video src={videoname ?`${API_URL}adminImages/cmsvideo/${videoname}` : ""} />
                                    </Button>
                                </div>
                            </div>}


                            {(identifier == "land_banner05") && <div className='text-end btn_view_oneline mb-4' >
                                <button className='btn btn-theme' onClick={() => handleAddcontent("add")}>Add</button>
                            </div>}
                            {console.log('sdfsadfsdf', contentarray)}
                            {contentarray && contentarray?.length > 0 && contentarray?.map((val, ind) =>
                                <div className='row'>
                                    {console.log("val_data", val, val?.Title)}
                                    <div className='col-sm-6 mb-4'>
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Feeback"
                                            className="mb-3" >
                                            <Form.Control placeholder='demo' type="text" value={val?.Content} id="Content" onChange={(e) => handlecontent(e?.target?.value, "Content", ind)} />
                                        </FloatingLabel>
                                    </div>


                                    <div className='col-sm-6 mb-4'>
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Name"
                                            className="mb-3" >
                                            <Form.Control placeholder='demo' type="text" value={val?.Title} id="Title" onChange={(e) => handlecontent(e?.target?.value, "Title", ind)} />
                                        </FloatingLabel>
                                    </div>


                                    <div className='col-sm-6 mb-4'>
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label=""
                                            className="mb-3" >
                                            <Form.Control placeholder='demo' type="text" value={val?.Heading} id="Heading" onChange={(e) => handlecontent(e?.target?.value, "Heading", ind)} />
                                        </FloatingLabel>
                                    </div>
                                    {/* <br></br> */}
                                    <div className='col-sm-12 mb-4'>
                                        <div className='text-end btn_view_oneline' >
                                            <button className='btn btn-theme' onClick={() => handleAddcontent("sub", ind)}>Remove</button>
                                        </div>
                                    </div>
                                    <br></br>

                                </div>


                            )


                            }

                            {/* </div> */}





                            <div className='text-end mt-3 btn_view_oneline' >
                                <button className='btn btn-theme me-3' onClick={handlesubmit}>Submit</button>
                                <Button variant="outline-dark" className='f-12 fw-600 outlinebtn' onClick={() => { push("/managepropertytype") }}>Cancel</Button>
                            </div>
                        </div>

                    </div>


                </div>
            </div>
        </div>
    )
}

export default FormView