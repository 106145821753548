import React,{useEffect, useState} from 'react';
import AdminHeader from './AdminHeader';
import Sidebar from './Sidebar';
import {FloatingLabel, Form,Button} from 'react-bootstrap'
import DataTable from 'react-data-table-component';
// import DataTable  from '@ashvin27/react-datatable';
import { Bar } from "react-chartjs-2";
import Datepicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import {gettotalbookingcounthook , gettotalcustomercounthook ,
    gettotalguestusercounthook , gettotalhostusercounthook ,
    getnewbookingcounthook , getnewcustomercounthook ,
    getnewguestusercounthook , getnewhostusercounthook , 
    getalluserhook , filtersingledayuserhook, DateTimeForm
    } from '../../Hooks/adminHook';
import { useHistory } from 'react-router-dom';
const Dashboard = () =>{
    const {push} = useHistory();
    const [totalbookingcount , setTotalbookingcount] = useState('');
    const [totalcustomercount , setTotalcustomercount] = useState('');
    const [totalguestusercount , setTotalguestusercount] = useState('');
    const [totalhostusercount , setTotalhostusercount] = useState('');
    const [newbookingcount , setNewbookingcount] = useState('');
    const [newcustomercount , setNewcustomercount] = useState('');
    const [newguestusercount , setNewguestusercount] = useState('');
    const [newhostusercount , setNewhostusercount] = useState('');
    const [users , setUser] = useState('');
    const [filtereduser , setFiltereduser] = useState([]);
    const [startDate , setStartDate] = useState('');
    // const [endDate , setEndDate] = useState('');
    const [status , setStatus] = useState(false);
    async function fetchdata(){
        await gettotalbookingcounthook().then((result)=>{
            console.log("gettotalbookingcount" , result);
            setTotalbookingcount(result?.data?.data?.record);
        });
        await gettotalcustomercounthook().then((result)=>{
            console.log("gettotalcustomercounthook" , result);
            setTotalcustomercount(result?.data?.data?.record)
        });
        await gettotalguestusercounthook().then((result)=>{
            console.log("gettotalguestusercounthook" , result);
            setTotalguestusercount(result?.data?.data?.record);
        });
        await gettotalhostusercounthook().then((result)=>{
            console.log("gettotalhostusercounthook" , result);
            setTotalhostusercount(result?.data?.data?.record);
        });
        await getnewbookingcounthook().then((result)=>{
            console.log("getnewbookingcounthook" , result);
            setNewbookingcount(result?.data?.data?.record);
        });
        await getnewcustomercounthook().then((result)=>{
            console.log("getnewcustomercounthook" , result);
            setNewcustomercount(result?.data?.data?.record);
        });
        await getnewguestusercounthook().then((result)=>{
            console.log("getnewguestusercounthook" , result);
            setNewguestusercount(result?.data?.data?.record);
        });
        await getnewhostusercounthook().then((result)=>{
            console.log("getnewhostusercounthook" , result);
            setNewhostusercount(result?.data?.data?.record);
        });
        await getalluserhook().then((result) => {
            console.log("getalluserhook" , result?.data?.data?.record);
            (result?.data?.data?.record).map((data , i) => data.serial = i+1)
            setUser(result?.data?.data?.record);
        })
    }

    useEffect(()=>{
        fetchdata();
    } , [])

    const columns = [
        {
            name:"Id",
            reorder: true,
            cell: (data) =>(data.serial)
        },
        {
            name:"Firstname",
            cell: (data) =>(data.firstName)           
        },
        // {
        //     name:"Lastname",
        //     cell: (data) =>(data.lastName)          
        // },
        {
            name:"Email",
            cell: (data) =>(data.email)
        },
        {
            name:"Country",
            cell: (data) =>(data.countrycode)
        },
        {
            name:"Is Host",
            cell: (data) =>(data.ishost?"Active" : "Inactive")
        },
        {
            name:"Login Type",
            cell: (data) =>(data.loginType)
        },
        {
            name:"CreatedAt",
            cell: (data) =>(DateTimeForm(String(data?.createdAt))?.split(",")[0]  + "   " + DateTimeForm(String(data?.createdAt))?.split(",")[1])
        },
        {
            name:"UpdatedAt",
            cell: (data) =>(DateTimeForm(String(data?.updatedAt))?.split(",")[0]  + "   " + DateTimeForm(String(data?.updatedAt))?.split(",")[1])
        }
    ]
    // const data = [
    //     {
    //         id:1,
    //         reserveid:"1002",
    //         Roomname:"A Unique Studio",
    //         username:"David" ,
    //         emailid:"example@gmail.com",
    //         userdatafrom:"16.09.2022",
    //         userdatato:"22.09.2022",
    //         iddocuments:"file.pdf",
    //         staus:"approved"
    //     },
    //     {
    //         id:2,
    //         reserveid:"1002",
    //         Roomname:"A Unique Studio",
    //         username:"David" ,
    //         emailid:"example@gmail.com",
    //         userdatafrom:"16.09.2022",
    //         userdatato:"22.09.2022",
    //         iddocuments:"file.pdf",
    //         staus:"approved"
    //     },
    //     {
    //         id:3,
    //         reserveid:"1002",
    //         Roomname:"A Unique Studio",
    //         username:"David" ,
    //         emailid:"example@gmail.com",
    //         userdatafrom:"16.09.2022",
    //         userdatato:"22.09.2022",
    //         iddocuments:"file.pdf",
    //         staus:"approved"
    //     },
    //     {
    //         id:4,
    //         reserveid:"1002",
    //         Roomname:"A Unique Studio",
    //         username:"David" ,
    //         emailid:"example@gmail.com",
    //         userdatafrom:"16.09.2022",
    //         userdatato:"22.09.2022",
    //         iddocuments:"file.pdf",
    //         staus:"approved"
    //     }
     
    // ]

    // const columns = [
    //     {
    //         key: "id",
    //         text: "Id",
    //         className: "name",
    //         align: "left",
    //         sortable: true,
    //     },
    //     {
    //         key: "reserveid",
    //         text: "Reserve Id",
    //         className: "name",
    //         align: "left",
    //         sortable: true,
    //     },
    //     {
    //         key: "Roomname",
    //         text: "Room Name",
    //         className: "name",
    //         align: "left",
    //         sortable: true,
    //     },
    //     {
    //         key: "username",
    //         text: "User Name",
    //         className: "name",
    //         align: "left",
    //         sortable: true,
    //     },
    //     {
    //         key: "emailid",
    //         text: "Email",
    //         className: "name",
    //         align: "left",
    //         sortable: true,
    //     },
    //     {
    //         key: "userdatafrom",
    //         text: "Id",
    //         className: "name",
    //         align: "left",
    //         sortable: true,
    //     },
    //     {
    //         key: "userdatato",
    //         text: "Id",
    //         className: "name",
    //         align: "left",
    //         sortable: true,
    //     },
    //     {
    //         key: "iddocuments",
    //         text: "Id",
    //         className: "name",
    //         align: "left",
    //         sortable: true,
    //     },
    //     {
    //         key: "staus",
    //         text: "Id",
    //         className: "name",
    //         align: "left",
    //         sortable: true,
    //     }
    // ]
    const config = {
        page_size: 10,
        length_menu: [ 10, 20, 50 ],
        filename: "admins",
        no_data_text: 'No user found!',
        button: {
            excel: true,
            print: true,
            csv: true,
        },
        className: "table",

        language: {
            length_menu: "Show _MENU_",
            filter: "Filter User",
            info: "Showing _START_ to _END_ of _TOTAL_ records",
            pagination: {
                first:"<<",
                previous: "<",
                next: ">",
                last: ">>"
            }
        },
        show_length_menu: true,
        show_filter: true,
        show_pagination: true,
        show_info: true,
    };
    

const labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun"];

const datas = {
  labels: labels,
  datasets: [
    {
      label: "My First dataset",
      backgroundColor: "#f5593e",
      borderColor: "#f5593e",
      data: [0, 10, 5, 2, 20, 30, 45],
    },
  ],
};

const handleDateChange = async(date) => {
    console.log("handle date change :",date);
    // setStartDate(date);
    await filtersingledayuserhook(date).then((result) => {
        console.log("handledate change result" ,result);
        if(result?.data?.type == "success")
        {
            setStatus(true);
        }
        setFiltereduser(result?.data?.record);
    })
 }

    return(
        <div id="dashboard" className='showsidebar'>
            <div id='sidebar'>
                <Sidebar/>
            </div>
            <div className='headandcont'>
               <div className="adminheader"><AdminHeader /></div> 
               <div className='cont'>
                <h4 className='adminblue my-4'>Dashboard</h4>
                {/* <div className='admin-forms mb-4 '>
                    <div className='shadowbox p-4 br-10'>
                        <div className='d-sm-flex gap-4 flex-1 px-4 pt-3'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="First Name"
                                className="mb-3" >
                            <Form.Control type="email" placeholder="name@example.com" />
                            </FloatingLabel>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Last Name"
                                className="mb-3" >
                                <Form.Control type="email" placeholder="name@example.com" />
                            </FloatingLabel>
                        </div>
                        <div className='d-sm-flex gap-4 flex-1 px-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="First Name"
                                className="mb-3" >
                            <Form.Control type="email" placeholder="name@example.com" />
                            </FloatingLabel>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Last Name"
                                className="mb-3" >
                                <Form.Control type="email" placeholder="name@example.com" />
                            </FloatingLabel>
                        </div>
                        <div className='d-md-flex gap-4 flex-1 px-4 h-55 ' > */}
                        {/* <FloatingLabel controlId="floatingSelect" label="Works with selects" className=' mt-4'> */}
                            {/* <Form.Select aria-label="Floating label select example" className='mb-3'>
                                <option>Open this select menu</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </Form.Select> */}
                            {/* </FloatingLabel> */}
                          
                                {/* <Form.Control type="date" placeholder="name@example.com" className='mb-3' />
                        </div>
                        <div className='px-4 text-end mt-3' >
                            <button className='btn btn-theme me-3'>Submit</button>
                            <Button variant="outline-dark" className='f-12 fw-600 outlinebtn'>Cancel</Button>
                        </div>
                    </div>
                
                </div> */}
                <div className='dash-state'>
                    <div className='gradbox shadowbox br-10' onClick={()=>{push("/managebooking")}}>
                        <p className='f-14 text-muted fw-600'>Total</p>
                        <div className='circleprog ms-4'>
                            <span class="title timer" data-from="0" data-to="85" data-speed="1800">{totalbookingcount}</span>
                            <div class="overlay"></div>
                        </div>
                        <p className='ms-4 f-14 fw-600 text-muted mt-3' >No.of Bookings</p>
                    </div>
                    <div className='gradbox shadowbox br-10' >
                        <p className='f-14 text-muted fw-600'>Total</p>
                        <div className='circleprog ms-4'>
                            <span class="title timer" data-from="0" data-to="85" data-speed="1800">{totalcustomercount}</span>
                            <div class="overlay"></div>
                        </div>
                        <p className='ms-4 f-14 fw-600 text-muted mt-3' >No.of Customers</p>
                    </div>
                    <div className='gradbox shadowbox br-10' onClick={()=>{push("/manageuser")}}>
                        <p className='f-14 text-muted fw-600'>Total</p>
                        <div className='circleprog ms-4'>
                            <span class="title timer" data-from="0" data-to="85" data-speed="1800">{totalguestusercount}</span>
                            <div class="overlay"></div>
                        </div>
                        <p className='ms-4 f-14 fw-600 text-muted mt-3' >No.of Guestusers</p>
                    </div>
                    <div className='gradbox shadowbox br-10' onClick={()=>{push("/managehost")}}>
                        <p className='f-14 text-muted fw-600'>Total</p>
                        <div className='circleprog ms-4'>
                            <span class="title timer" data-from="0" data-to="85" data-speed="1800">{totalhostusercount}</span>
                            <div class="overlay"></div>
                        </div>
                        <p className='ms-4 f-14 fw-600 text-muted mt-3' >No.of Hostusers</p>
                    </div>
                    <div className='gradbox shadowbox br-10' onClick={()=>{push("/managebooking")}}>
                        <p className='f-14 text-muted fw-600'>New</p>
                        <div className='circleprog ms-4'>
                            <span class="title timer" data-from="0" data-to="85" data-speed="1800">{newbookingcount}</span>
                            <div class="overlay"></div>
                        </div>
                        <p className='ms-4 f-14 fw-600 text-muted mt-3' >No.of Bookings</p>
                    </div>
                    <div className='gradbox shadowbox br-10'>
                        <p className='f-14 text-muted fw-600'>New</p>
                        <div className='circleprog ms-4'>
                            <span class="title timer" data-from="0" data-to="85" data-speed="1800">{newcustomercount}</span>
                            <div class="overlay"></div>
                        </div>
                        <p className='ms-4 f-14 fw-600 text-muted mt-3' >No.of customers</p>
                    </div>
                    <div className='gradbox shadowbox br-10' onClick={()=>{push("/manageuser")}}>
                        <p className='f-14 text-muted fw-600'>New</p>
                        <div className='circleprog ms-4'>
                            <span class="title timer" data-from="0" data-to="85" data-speed="1800">{newguestusercount}</span>
                            <div class="overlay"></div>
                        </div>
                        <p className='ms-4 f-14 fw-600 text-muted mt-3' >No.of Guestusers</p>
                    </div>
                    <div className='gradbox shadowbox br-10' onClick={()=>{push("/managehost")}}>
                        <p className='f-14 text-muted fw-600'>New</p>
                        <div className='circleprog ms-4'>
                            <span class="title timer" data-from="0" data-to="85" data-speed="1800">{newhostusercount}</span>
                            <div class="overlay"></div>
                        </div>
                        <p className='ms-4 f-14 fw-600 text-muted mt-3' >No.of Hostusers</p>
                    </div>
                </div>
                <div className='d-xl-flex flex-1 mt-3 gap-3'>
                    <div className='barchrt  shadowbox p-3 br-10'><Bar data={datas} /></div>
                   
                    <div className='date-pic shadowbox p-3 br-10'>
                        <h6 className='adminblue fw-600 mt-3 me-2'><span className='fa fa-calendar'></span> Calendar</h6>
                        {/* <Datepicker  className=' '
                        selectsStart={true}
                        monthsShown={1}
                        minDate={new Date()}
                        shouldCloseOnSelect={false}
                        inline={true}/> */}
                        <Datepicker  className=' '
                            onChange={(date) => handleDateChange(date)}
                            selectsStart={true}
                            monthsShown={1}
                            // minDate={new Date()}
                            shouldCloseOnSelect={false}
                            selected={startDate}
                            // startDate={startDate}
                            // endDate={endDate}
                            inline={true}/>
                   </div>
                </div>
                <div className='tablesec shadowbox px-3 mt-3 br-10 mb-5'>
                    <h6 className='adminblue pt-4 me-2' ><span className='fa fa-book'></span> View Guest Users</h6>
                    <DataTable pagination columns={columns} data={status ? filtereduser : users} />
                    {/* <DataTable striped responsive
                        config={config}
                        className={config.className}
                        records={data}
                        columns={columns}

                    /> */}
               </div>
               </div> 
            </div>
        </div>
    )
}

export default Dashboard