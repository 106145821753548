import React,{useState , Fragment, useEffect} from 'react';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';
import {FloatingLabel, Form,Button} from 'react-bootstrap'
// import DataTable from 'react-data-table-component';
import DataTable  from '@ashvin27/react-datatable';
import { Bar } from "react-chartjs-2";
import Datepicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from 'react-router-dom';
import { deleteamenitytypehook, getpropertytypegrouphook } from '../../../Hooks/adminHook';
import { API_URL } from '../../../config/env';
const TableView = () =>{
    // const navigate = useNavigate();
    const {push} = useHistory();
    const [propertytypegroup , setPropertytypegroup] = useState([]);
    const [path , setPath] = useState('')

    useEffect(()=>{
        async function fetchdata(){
            await getpropertytypegrouphook().then((result) => {
                console.log("get property type group result" , result);
                setPropertytypegroup(result?.data?.data?.record[0]?.content);
                setPath(result?.data?.data?.record[0]?.path);
            })
        }
        fetchdata();
    },[])
    const columns = [
        {
            key: "id",
            text: "Id",
            className: "name",
            align: "left",
            sortable: true,
        },
        {
            key: "type",
            text: "Type",
            className: "name",
            align: "left",
            // sortable: true,
        },
        {
            key: "description",
            text: "Description",
            className: "name",
            align: "left",
            // sortable: true,
        },
        {
            key: "image",
            text: "Image",
            className: "name",
            align: "left",
            // sortable: true,
            cell: (data) =>(<div className='d-flex upimages my-1 px-4'><a  href = {API_URL+path+"/"+data?.image} target="_blank">
        <img src={API_URL+path+"/"+data?.image} onclick="window.open(this.src, '_blank');" />
        </a></div>)
        },
        {
            key: "status",
            text: "Status",
            className: "name",
            align: "left",
            sortable: true,
        },
        {
            key: "action",
            text: "Action",
            className: "action",
            width: 100,
            align: "left",
            // sortable: true,
            cell: record => {
                return (
                    <Fragment>
                        {/* <button
                            className="btn btn-primary btn-sm"
                            // onClick={() => viewRecord(record)}
                            style={{ marginRight: '5px' }}>
                            <i class="fa fa-eye mr-0" ></i>
                        </button> */}
                        <button
                            className="btn btn-primary btn-sm"
                            onClick={() => editpropertytypegroup(record)}
                            style={{ marginRight: '5px' }}
                            >
                            <i className="fa fa-edit mr-0"></i>
                        </button>
                        {/* <button
                            className="btn btn-danger btn-sm"
                            onClick={() => uploadImage(record)}>
                            <i className="fa fa-edit mr-0"></i>
                        </button> */}
                        <button
                            className="btn btn-primary btn-sm"
                            onClick={() => deletepropertytypegroup(record)}
                            style={{ marginRight: '5px' }}>
                            <i className="fa fa-trash mr-0"></i>
                        </button>
                    </Fragment>
                );
            }
        }
    ]
        

    const config = {
        page_size: 10,
        length_menu: [ 10, 20, 50 ],
        filename: "Users",
        no_data_text: 'No user found!',
        button: {
            excel: true,
            print: true,
            csv: true
        },className: "table",
        language: {
            length_menu: "Show _MENU_",
            filter: "Filter User",
            info: "Showing _START_ to _END_ of _TOTAL_ records",
            pagination: {
                first:"<<",
                previous: "<",
                next: ">",
                last: ">>"
            }
        },
        show_length_menu: true,
        show_filter: true,
        show_pagination: true,
        show_info: true,
    };
  

const labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun"];

// const datas = {
//   labels: labels,
//   datasets: [
//     {
//       label: "My First dataset",
//       backgroundColor: "rgb(255, 99, 132)",
//       borderColor: "rgb(255, 99, 132)",
//       data: [0, 10, 5, 2, 20, 30, 45],
//     },
//   ],
// };

const editpropertytypegroup = async(dat) => {
    let data = {
        data : dat,
        path : path
    }
    push("/editpropertytypegroup" , {state :data });
}

const deletepropertytypegroup = async(data) => {
    let payload = {
        id : data?.id,
        name : "propertyTypeGroup"
    }
    await deleteamenitytypehook(payload).then((result) => {
        console.log("delete amenity type result" , result);
        window.location.reload();
    })
}

    return(
        <div id="dashboard" className='showsidebar'>
            <div id='sidebar'>
                <Sidebar/>
            </div>
            <div className='headandcont'>
               <div className="adminheader"><AdminHeader /></div> 
               <div className='cont'>
                <h4 className='adminblue my-4'>Property Type Group</h4>
                <div className='pl-4 text-end mt-3' >
                    <button className='btn btn-theme me-0' onClick={()=> {push('/addpropertytypegroup')}}>Add</button>
                </div>
                
             
              
                <div className='tablesec shadowbox px-3 mt-3 br-10 mb-5'>
                    <h6 className='adminblue pt-4 me-2' ><span className='fa fa-book'></span> View Property Type Group</h6>
                    {/* <DataTable pagination columns={columns} records={data} config = {config} className={config.className}/> */}
                    <DataTable striped responsive
                        config={config}
                        className={config.className}
                        records={propertytypegroup}
                        columns={columns}

                    />
               </div>
               </div> 
            </div>
        </div>
    )
}

export default TableView