import React,{useEffect, useState} from 'react';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';
import {FloatingLabel, Form,Button} from 'react-bootstrap'
import DataTable from 'react-data-table-component';
import { Bar } from "react-chartjs-2";
import Datepicker from 'react-datepicker';
import ReactSummernote from 'react-summernote';
import 'react-summernote/dist/react-summernote.css'; 
// import 'react-summernote/lang/summernote-ru-RU';
import 'bootstrap/js/src/modal.js';
import 'bootstrap/js/src/dropdown.js';
import 'bootstrap/js/src/tooltip.js';
import 'bootstrap/dist/css/bootstrap.css';
import "react-datepicker/dist/react-datepicker.css";
import { addcmshook, editcmshook, editfaqhook } from '../../../Hooks/adminHook';
import { useHistory, useLocation } from 'react-router-dom';
import { API_URL } from '../../../config/env';
const FormView = () =>{
  const [status , setStatus] = useState("");
  console.log("status_data", status)
  const [name , setName] = useState("");
  const [content , setContent] = useState("");
  const [content1 , setContent1] = useState("");
  const [image , setImage] = useState();
  const [imageview , setImageview] = useState();
  const [path , setPath] = useState();
  const [identifier, setIdentifier] = useState('')
  const [cmsdata, setCmsData] = useState({});
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('')
  const [multipleimage, setMultipleImages] = useState([])
  const [multipleimageerror, setMultipleImageError] = useState({})
  const [multipleimageview, setMultipleImageView] = useState([])
  const [content2, setContent2] = useState('')

  const [question, setQuestion] = useState('')
  const [answer, setAnswer] = useState('')
  const [heading, setHeading] = useState('')
 console.log("multipleimageviewsdafasdf", multipleimageerror,multipleimageview,multipleimage)
  const {push} = useHistory();
  const location = useLocation();
  let regex = /^[0-9]+$/;

  useEffect(()=>{
    document.getElementById("managefaq").classList.add("active");
    if(window.innerWidth>767)
    {
        // console.log(document.querySelectorAll(".list-group-item-action.active")[0].offsetTop,"top");
        if(document.querySelectorAll(".themescroll li a.active").length>0)
        {           
             var pagelinkofset = document.querySelectorAll(".themescroll li a.active")[0].offsetTop;
      console.log(pagelinkofset,"pagelinkofset")
             document.getElementById("scrollbar_sidebar_id").scrollTo(0,pagelinkofset - 200);

        }

    }
    else
    {
        // alert(2)
        if(document.querySelectorAll(".themescroll li a.active").length>0)
        {
            // console.log(document.querySelectorAll(".list-group-item-action.active")[0].offsetTop,"top");
            if(document.querySelectorAll(".themescroll li a.active").length>0)
            {  
            var pagelinkofset = document.querySelectorAll(".themescroll li a.active")[0].offsetTop;
            document.getElementById("scrollbar_sidebar_id").scrollTo(0,pagelinkofset - 200);
            }
        }
        
    }
},[]);
  useEffect(()=>{
    console.log("location editfaq" , location?.state?.state, location?.state?.state);
    // setName(location?.state?.state?.name);
    // setHeading(location?.state?.state?.heading)
    // setContent(location?.state?.state?.content);
    // setContent1(location?.state?.state?.content1)
    // setContent2(location?.state?.state?.content2)
    // setStatus(location?.state?.state?.status);
    // setImage(location?.state?.state?.image);
    // setPath(location?.state?.state?.path);
    // setCmsData(location?.state?.state)
    // setPhone(location?.state?.state?.phone);
    // setAddress(location?.state?.state?.address)
    // setIdentifier(location?.state?.state?.identifier)
    // setMultipleImages(location?.state?.state?.multipleimage)
    setQuestion(location?.state?.state?.question)
    setAnswer(location?.state?.state?.answer)
    
  } ,[])

    const handlesubmit = async () => {
        if (answer?.length > 0 && question?.length > 0) {
            let payload = {
                answer: answer,
                question: question,
                id: location?.state?.state?._id
            };
            console.log("cmseditpayload", payload);
            await editfaqhook(payload).then((result) => {
                console.log("edit faq hook result", result);
                push("/faq")
            })
        }
        else {
            window.alert("Fill all the fields !..");
        }
    }


    const handleMultipleImage = async (e) => {
        try {
            console.log("e.target.images", e.target.files, e.target.files?.length);
            let arr = [];
           if(e.target.name == "aboutsbanner_01"){
            if (e.target.files?.length <= 4) {
                if(multipleimage?.length >= 4){
                     arr = [...arr, ...e.target.files];
                    setMultipleImageView(arr);
                    setMultipleImages(arr);
                } else {
                    setMultipleImageView([...multipleimageview, ...e.target.files])
                    setMultipleImages([...multipleimage, ...e.target.files])
                }
            } else {
                setMultipleImageError('choose only 4 imgaes')
            }
           } else {
            if (e.target.files?.length <= 2) {
                if(multipleimage?.length >= 2){
                     arr = [...arr, ...e.target.files];
                    setMultipleImageView(arr);
                    setMultipleImages(arr);
                } else {
                    setMultipleImageView([...multipleimageview, ...e.target.files])
                    setMultipleImages([...multipleimage, ...e.target.files])
                }
            } else {
                setMultipleImageError('choose only 4 imgaes')
            }
           }
            // if()
        } catch (e) {
            console.log("handleMultipleImage_err", e)
        }
    }
    return(
        <div id="dashboard" className='showsidebar'>
            <div id='sidebar'>
                <Sidebar/>
            </div>
            <div className='headandcont'>
               <div className="adminheader"><AdminHeader /></div> 
               <div className='cont'>
                <h4 className='adminblue my-4'>Edit FAQ CMS</h4>
                <div className='admin-forms mb-4 '>
                    <div className='shadowbox p-4 br-10'>
                        {/* <div className='d-sm-flex gap-4 flex-1 px-4 pt-3'>
                           { <FloatingLabel
                                controlId="floatingInput"
                                label="Heading"
                                className="mb-3" >
                            <Form.Control type="text" placeholder="name@example.com" defaultValue={heading} readOnly={true} onChange={(e)=>{setHeading(e?.target?.value)}}/>
                            </FloatingLabel>}
                            <div className='col-sm-12 mb-4'>
                            <FloatingLabel controlId="floatingSelect" label="Status *">
                                <Form.Select aria-label="Floating label select example" value={status} onChange={(e)=>setStatus(e?.target?.value)}>
                                    <option value ="" hidden>Select Status</option>
                                    <option value='Active'>Active</option>
                                    <option value='Inactive'>Inactive</option>
                                </Form.Select>
                            </FloatingLabel>
                            </div>
                        </div> */}
                        

                        { <div className='d-sm-flex gap-4 flex-1 px-4 pt-3'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Question"
                                className="mb-3" >
                            <Form.Control type="text" placeholder="name@example.com" defaultValue={question} onChange={(e) => {  { setQuestion(e.target.value) } }} />
                            </FloatingLabel>
                        </div>}
                        <div className='px-4'>
                            <h5 className='mb-3'>Answer</h5>
                            
                           <ReactSummernote
                            value={answer}
                            options={{
                                height: 350,
                                dialogsInBody: true,
                                toolbar: [
                                    ['style', ['style']],
                                    ['font', ['bold', 'underline', 'clear']],
                                    ['fontname', ['fontname']],
                                    ['para', ['ul', 'ol', 'paragraph']],
                                    ['table', ['table']],
                                    ['insert', ['link', 'picture', 'video']],
                                    ['view', ['fullscreen', 'codeview']]
                                ]
                            }}
                            onChange={(e)=>{console.log("e" ,e);setAnswer(e)}}
                        />
                        </div>
    
                        <div className='px-4 text-end mt-3 btn_view_oneline' >
                            <button className='btn btn-theme me-3' onClick={handlesubmit}>Submit</button>
                            <Button variant="outline-dark" className='f-12 fw-600 outlinebtn' onClick={()=>{push("/manage-cms")}}>Cancel</Button>
                        </div>
                    </div>
                
                </div>

                
               </div> 
            </div>
        </div>
    )
}


export default FormView