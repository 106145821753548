import React,{useState , useEffect , Fragment} from 'react';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';
import {FloatingLabel, Form,Button} from 'react-bootstrap'
// import DataTable from 'react-data-table-component';
import DataTable  from '@ashvin27/react-datatable';
import { Bar } from "react-chartjs-2";
import Datepicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { DateTimeForm, deactivateroomhook, getallroomshook } from '../../../Hooks/adminHook';
import { useHistory } from 'react-router-dom';
const TableView = () =>{
    const [rooms , setRooms] = useState([]);
    const [totalrecordcounts, setTotalRecordCounts] = useState(0)
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [status, setStatus] = useState(false)
    // const navigate = useNavigate();
    const {push} = useHistory();
    useEffect(() => {
        async function fetchdata(){
            let reqData = {
                page : page,
                limit : limit
            }
            await getallroomshook(reqData).then((result) => {
                console.log("get all room result" , result?.data?.data?.record);
                result?.data?.data?.record?.map((data , i) => data.serial = i+1)
                setRooms(result?.data?.data?.record);
                setTotalRecordCounts(result?.data?.data?.count);
            })
        }
        fetchdata();
    },[status])
    const columns = [
        {
            key: "serial",
            text: "Id",
            className: "name",
            align: "left",
            sortable: true,
        },
        {
            key: "title",
            text: "Room Name",
            className: "name",
            align: "left",
            // sortable: true,
        },
        {
            key: "firstNameasdf",
            text: "First Name",
            className: "name",
            align: "left",
            // sortable: true,
            cell: (data) =>((data?.firstName != undefined && data?.firstName != "undefined")? data?.firstName : "")
        
        },
        // {
        //     key: "lastName",
        //     text: "Last Name",
        //     className: "name",
        //     align: "left",
        //     // sortable: true,
        // },
        // {
        //     key: "privacyType",
        //     text: "Privacy Type",
        //     className: "name",
        //     align: "left",
        //     // sortable: true,
        // },
        {
            key: "email",
            text: "Email",
            className: "name",
            align: "left",
            // sortable: true,
        },
        {
            key: "price",
            text: "Price",
            className: "name",
            align: "left",
            sortable: true,
        },
        {
            key: "cancellationPolicy",
            text: "Cancellation Policy",
            className: "name",
            align: "left",
            // sortable: true,
        },
        {
            key: "status",
            text: "Status",
            className: "name",
            align: "left",
            sortable: true,
            // cell: (data) =>(data.isdeactivate?"Inactive" : "Active")
        },
        {
            key: "isDeactivate",
            text: "Deactivate Status",
            className: "name",
            align: "left",
            sortable: true,
            cell: (data) =>(data.isDeactivate?"true" : "false")
        },
        {
            key: "createdAt",
            text: "Created At",
            className: "name",
            align: "left",
            sortable: true,
            cell: (data) =>(DateTimeForm(String(data?.createdAt))?.split(",")[0]  + "   " + DateTimeForm(String(data?.createdAt))?.split(",")[1])
        },
        {
            key: "updatedAt",
            text: "Updated At",
            className: "name",
            align: "left",
            sortable: true,
            cell: (data) =>(DateTimeForm(String(data?.updatedAt))?.split(",")[0]  + "   " + DateTimeForm(String(data?.updatedAt))?.split(",")[1])
        },
        {
            key: "action",
            text: "Action",
            className: "action",
            width: 100,
            align: "left",
            // sortable: true,
            cell: record => {
                return (
                    <Fragment>
                        {/* <button
                            className="btn btn-primary btn-sm"
                            // onClick={() => viewRecord(record)}
                            style={{ marginRight: '5px' }}>
                            <i class="fa fa-eye mr-0" ></i>
                        </button> */}
                        <button
                            className="btn btn-primary btn-sm"
                            onClick={() => editroom(record)}
                            // style={{ marginRight: '5px' }}
                            >
                            <i className="fa fa-edit mr-0"></i>
                        </button>
                        <button
                            className="btn btn-primary btn-sm"
                            onClick={() => deactivateroom(record)}
                            >
                            {/* <i className="fa fa-trash mr-0"></i> */}
                            
                            {record?.isDeactivate != true ? <i class="fa fa-eye"></i> :
                             <i class="fa fa-eye-slash"></i>}
                            {/* <i class="fa fa-eye-slash"></i> */}
                        </button>
                        {/* <button
                            className="btn btn-primary btn-sm"
                            onClick={() => WhiteList(record)}
                            style={{ marginRight: '5px' }}>
                            <i className="fa fa-edit mr-0"></i>
                        </button> */}
                    </Fragment>
                );
            }
        }
        // {
        //     name:"Id",
        //     reorder: true,
        //     cell: (data) =>(data.id)
        // },
        // {
        //     name:"Reservation Id",
        //     cell: (data) =>(data.reserveid)           
        // },
        // {
        //     name:"Room name",
        //     cell: (data) =>(data.Roomname)          
        // },
        // {
        //     name:"User Name",
        //     cell: (data) =>(data.username)
        // },
        // {
        //     name:"Email Id",
        //     cell: (data) =>(data.emailid)
        // },
        // {
        //     name:"User Date From",
        //     cell: (data) =>(data.userdatafrom)
        // },
        // {
        //     name:"User Date To",
        //     cell: (data) =>(data.userdatato)
        // },
        // {
        //     name:"Id Document",
        //     cell: (data) =>(data.iddocuments)
        // },
        // {
        //     name:"Status",
        //     cell: (data) =>(data.staus)
        // }
    ]
   

    const config = {
        page_size: 10,
        length_menu: [ 10, 20, 50 ],
        filename: "Users",
        no_data_text: 'No user found!',
        button: {
            excel: true,
            print: true,
            csv: true
        },className: "table",
        language: {
            length_menu: "Show _MENU_",
            filter: "Filter User",
            info: "Showing _START_ to _END_ of _TOTAL_ records",
            pagination: {
                first:"<<",
                previous: "<",
                next: ">",
                last: ">>"
            }
        },
        show_length_menu: true,
        show_filter: true,
        show_pagination: true,
        show_info: true,
    };
  

const labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun"];

// const datas = {
//   labels: labels,
//   datasets: [
//     {
//       label: "My First dataset",
//       backgroundColor: "rgb(255, 99, 132)",
//       borderColor: "rgb(255, 99, 132)",
//       data: [0, 10, 5, 2, 20, 30, 45],
//     },
//   ],
// };

const deactivateroom = async(data) => {
    console.log("deactivate", data?.isDeactivate);
    let reqdata = {
        id : data?._id,
        status : data?.isDeactivate == true ? false : true
    }
    console.log('req datssssss', reqdata)
    await deactivateroomhook(reqdata).then((result) => {
        console.log("deactivate room hook" , result);
        if(result?.data?.type == 'success') {
            setStatus(!status)
        }
        if(result?.data?.type == 'success') {
            if(result?.data?.record?.isDeactivate != true) {
                window.alert("Room Active successfully !");
            } else {
                window.alert("Room DeActive successfully !");
            }

        }
        // window.location.reload();
    })
}


const pageChange = async (index) => {

    try {
        let reqData = {
            page: index.page_number,
            limit : index.page_size,
            search: index.filter_value
        }
        await getallroomshook(reqData).then((result) => {
            console.log("get all room result" , result?.data?.data?.record);
            result?.data?.data?.record?.map((data , i) => data.serial = i+1)
            setRooms(result?.data?.data?.record);
        })
        console.log('index_data', index)
    } catch (err) {
        console.log("pageChange_err", err)
    }
} 


const editroom = async(data) => {
    push("/editroom" , {state : data})
}

    return(
        <div id="dashboard" className='showsidebar'>
            <div id='sidebar'>
                <Sidebar/>
            </div>
            <div className='headandcont'>
               <div className="adminheader"><AdminHeader /></div> 
               <div className='cont'>
                <h4 className='adminblue my-4'>Rooms</h4>
                
             
              
                <div className='tablesec shadowbox px-3 mt-3 br-10 mb-5'>
                    <h6 className='adminblue pt-4 me-2' ><span className='fa fa-book'></span> View Rooms</h6>
                    {/* <DataTable pagination columns={columns} records={data} config = {config} className={config.className}/> */}
                    <DataTable striped responsive
                        config={config}
                        className={config.className}
                        records={rooms}
                        columns={columns}
                        dynamic={true}
                        total_record={totalrecordcounts}
                        onChange={pageChange}

                    />
               </div>
               </div> 
            </div>
        </div>
    )
}

export default TableView