import React,{useEffect, useState} from 'react';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';
import {FloatingLabel, Form,Button} from 'react-bootstrap'
import DataTable from 'react-data-table-component';
import { Bar } from "react-chartjs-2";
import Datepicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useHistory } from 'react-router-dom';
import { editpropertytypehook } from '../../../Hooks/adminHook';
import { API_URL } from '../../../config/env';
const FormView = (props) =>{
  
    const location = useLocation();
    // const navigate = useNavigate();
    const {push} = useHistory();
    const [propertytype , setPropertytype] = useState({});
    const [status , setStatus] = useState('');
    const [heading , setHeading] = useState('');
    const [content , setContent] = useState('');
    const [imageview , setImageview] = useState("");
    const [image , setImage] = useState("");

    useEffect(()=>{
        document.getElementById("managepropertytype").classList.add("active");
        if(window.innerWidth>767)
        {
            // console.log(document.querySelectorAll(".list-group-item-action.active")[0].offsetTop,"top");
            if(document.querySelectorAll(".themescroll li a.active").length>0)
            {           
                 var pagelinkofset = document.querySelectorAll(".themescroll li a.active")[0].offsetTop;
          console.log(pagelinkofset,"pagelinkofset")
                 document.getElementById("scrollbar_sidebar_id").scrollTo(0,pagelinkofset - 200);

            }

        }
        else
        {
            // alert(2)
            if(document.querySelectorAll(".themescroll li a.active").length>0)
            {
                // console.log(document.querySelectorAll(".list-group-item-action.active")[0].offsetTop,"top");
                if(document.querySelectorAll(".themescroll li a.active").length>0)
                {  
                var pagelinkofset = document.querySelectorAll(".themescroll li a.active")[0].offsetTop;
                document.getElementById("scrollbar_sidebar_id").scrollTo(0,pagelinkofset - 200);
                }
            }
            
        }
    },[]);

    useEffect(()=> {
        console.log("location data" , location?.state);
        setPropertytype(location?.state?.state);
        setStatus(location?.state?.state?.status);
        setHeading(location?.state?.state?.heading);
        setContent(location?.state?.state?.content);
        setImage(location?.state?.state?.image);
    },[]);

    
  

    const handlesubmit = async() => {
        if(heading?.length > 0 && content?.length >0 && status?.length >0) 
        {
            let payload = {
                id : propertytype?.id,
                heading : heading,
                content : content,
                status : status,
                image : image
            } 
            await editpropertytypehook(payload).then((result) => {
                console.log("edit propertytype result" , result);
                push("/managepropertytype");
            })
        }
        else{
            window.alert("Fill all the fields !");
        }
    }
    return(
        <div id="dashboard" className='showsidebar'>
            <div id='sidebar'>
                <Sidebar/>
            </div>
            <div className='headandcont'>
               <div className="adminheader"><AdminHeader /></div> 
               <div className='cont'>
                <h4 className='adminblue my-4'>Edit Property Type</h4>
                <div className='admin-forms mb-4 '>
                    <div className='shadowbox p-4 br-10'>
                        <div className='row'>
                        <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Heading *"
                                className="mb-3" >
                            <Form.Control placeholder='demo' type="text" defaultValue={propertytype?.heading} onChange = {(e)=> setHeading(e?.target?.value)} />
                            </FloatingLabel>
                            </div>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Content *">
                                <Form.Control placeholder='demo' type="text" defaultValue={propertytype?.content} onChange = {(e)=> setContent(e?.target?.value)} />
                            </FloatingLabel></div>
                        </div>
                        
                        <div className='row' >
                        <div className='col-sm-6 mb-4'>
                        <FloatingLabel controlId="floatingSelect" label="Status *">
                            <Form.Select aria-label="Floating label select example" value = {status} onChange = {(e) => setStatus(e?.target?.value)}>
                                <option value = "">Select status</option>
                                <option >Active</option>
                                <option >Inactive</option>
                                {/* <option value="3">Three</option> */}
                            </Form.Select>
                            </FloatingLabel>
                            </div>
                          
                                {/* <Form.Control placeholder='demo' type="date" placeholder="name@example.com" className='' /> */}
                        </div>
                        <div className='row'>
                            <div className='col-sm-6 mb-4'>
                        <h6>Image *:</h6>
                        <div className='upphoto my-3 ml-6'>
                        <Button variant="link" className='nounder text-dark me-2 mb-2'>
                                    <div className={imageview && 'd-none'}></div>
                                    <span className='fa fa-photo'></span>
                                   
                                    <p>Upload Image</p>
                                    {console.log("imageview ? imageview : API_URL +location?.state?.state?.path + image" , imageview ? imageview : `${API_URL}${location?.state?.path}/${image}`)}
                                    <input type="file"  name = "img" accept=".jpg,.jpeg,.png" onChange ={(e)=>{setImage(e?.target?.files[0]); setImageview(URL.createObjectURL(e?.target?.files[0]))}}/>
                                    <img src = {imageview ? imageview : `${API_URL}${location?.state?.path}/${image}`}/>
                                </Button>
                        </div>
                        </div>
                        </div>

                        <div className='px-4 text-end mt-3 btn_view_oneline' >
                            <button className='btn btn-theme me-3' onClick={handlesubmit}>Submit</button>
                            <Button variant="outline-dark" className='f-12 fw-600 outlinebtn' onClick={()=>{push("/managepropertytype")}}>Cancel</Button>
                        </div>
                    </div>
                
                </div>

                
               </div> 
            </div>
        </div>
    )
}

export default FormView